import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import DataUploader from "./components/DataUploader/DataUploader";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import CompanyTable from "./components/Table/CompanyTable";
import CampaignTable from "./components/Table/CampaignTable";
import InfluencerTable from "./components/Table/InfluencerTable";
import UserTable from "./components/Table/UserTable";
import DealsTable from "./components/Table/DealsTable";
import ProspectTable from "./components/Table/ProspectTable";
import { EmailAccounts } from "./components/EmailAccounts/EmailAccounts";
import { Sequences } from "./components/Sequences/Sequences";
import { NewSequences } from "./components/Sequences/NewSequences";
import { Navigate } from "react-router-dom";
import InboxTable from "./components/Table/InboxTable";
import TestBord from "./components/Table/TestBord";

import InfluencerDealsTable from "./components/Table/InfluencerDealsTable"; //Added by me......
import ViewDeals from "./components/Table/ViewDeals";
import Contract from "./components/Table/Contract";
import DealsTab from "./components/Table/DealsTab";
import AllDealsTab from "./components/Table/AllDealsTab";
import CreatorManager from "./pages/CreatorManager";
import Influencerlog from "./pages/Influencerlog";
import Profile from "./components/Table/Profile";
import Scouting from "./components/Table/Scouting";
import InboxAllEmails from "./components/Table/InboxAllEmails";
import InboxCardModel from "./components/Modal/InboxCardModel";
// import InboxAllEmails from './components/Table/InboxAllEmails';

const routesConfig = [
  {
    path: "/admin",
    component: AdminPage,
    role: "Admin",
  },
  {
    path: "/creator",
    component: CreatorManager,
    role: "Creator Manager",
  },
  {
    path: "/influencer",
    component: Influencerlog,
    role: "Influencer",
  },
];

function App() {
  const location = useLocation();

  let auth = localStorage.getItem("userEmail");

  const role = localStorage.getItem("Role") || location.state?.role;

  let routeConfig;

  switch (role) {
    case "Admin":
      routeConfig = routesConfig.find((route) => route.role === "Admin");
      break;
    case "Creator Manager":
      routeConfig = routesConfig.find(
        (route) => route.role === "Creator Manager"
      );
      break;
    case "Influencer":
      routeConfig = routesConfig.find((route) => route.role === "Influencer");
      break;
    default:
      routeConfig = { path: "/login", component: LoginPage };
  }

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          !role && !auth ? (
            <LoginPage />
          ) : (
            <Navigate to={`${routeConfig.path}/dashboard`} />
          )
        }
      />

      <Route
        exact
        path="/login"
        element={
          !role && !auth ? (
            <LoginPage />
          ) : (
            <Navigate to={`${routeConfig.path}/dashboard`} />
          )
        }
      />
      <Route
        exact
        path={routeConfig.path}
        element={
          !role && !auth ? (
            <LoginPage />
          ) : (
            <Navigate to={`${routeConfig.path}/dashboard`} />
          )
        }
      />
      {/* <Route path='/admin' element={<ProtectedRoutes component={AdminPage} />}> */}

      {auth && (
        <Route
          key={0}
          path={routeConfig.path}
          element={
            <ProtectedRoutes
              mainPath={routeConfig.path}
              component={routeConfig.component}
              role={routeConfig.role}
            />
          }
        >
          <Route exact path="dashboard" element={<TestBord />} />
          <Route exact path="uploader" element={<DataUploader />} />
          <Route exact path="company" element={<CompanyTable />} />
          <Route exact path="prospect" element={<ProspectTable />} />
          <Route exact path="Outreach" element={<CampaignTable />} />
          <Route exact path="influencer" element={<InfluencerTable />} />
          <Route exact path="accounts" element={<EmailAccounts />} />
          <Route exact path="sequences" element={<Sequences />} />
          <Route exact path="sequences/new" element={<NewSequences />} />
          <Route exact path="users" element={<UserTable />} />
          <Route exact path="deals" element={<DealsTab />} />
          <Route exact path="All_Deals" element={<AllDealsTab />} />
          <Route exact path="Inbox" element={<InboxTable />} />
          <Route
            exact
            path=":talent_name/cm/:id"
            element={<InfluencerDealsTable />}
          />
          <Route exact path=":talent_name/:id" element={<ViewDeals />} />
          <Route exact path="Contract_Review" element={<Contract />} />
          <Route exect path="profile" element={<Profile />} />
          <Route exect path="scouting" element={<Scouting />} />
          {/* <Route exect path = 'InboxAllEmails' element={<InboxAllEmails/>}/> */}
          <Route
            exect
            path=":currentTalName/Inbox"
            element={<InboxAllEmails />}
          />

          {/* <Route exact path='TestBord' element={<TestBord />} /> */}
          {/* //Added by me...... */}
        </Route>
      )}
      {/* <Route path="*" element={!auth ? <Navigate to="/login" /> : <h3>There's nothing here: 404!</h3>} /> */}
      <Route
           path="*"
          element={!auth ? <LoginPage /> : <h3>There's nothing here: 404!</h3>}
      />
    </Routes>
  );
}

export default App;
