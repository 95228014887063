import React, { useEffect, useMemo, useState } from "react";
import { PROSPECT_COLUMNS } from "./Columns";
import { useTable, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  Img,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import { format } from "date-fns";
import { AddIcon } from "@chakra-ui/icons";
import { TbMailSearch } from "react-icons/tb";
import ProspectFormModal from "../Modal/ProspectFormModal";
import { CreateCampaignModal } from "../Modal/CreateCampaignModal";
// import { fetchInfluencerMetaData } from '../../services/influencerApi';
// import { fetchAllSequences } from '../../services/sequenceApi';
// import { fetchUserEmails } from '../../services/userEmailsApi';
import ProspectDeleteDialogBox from "../Modal/ProspectDeleteDialogBox";
// import EmailsFormModel from '../Modal/EmailsTableModel';
import EmailsModel from "../Modal/EmailsModel";
import { BsFillSendPlusFill } from "react-icons/bs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";

import linkedInIcon from "./Linkedin Plus Icon.png";
import ProspectLinkedInFormModal from "../Modal/ProspectLinkedInFormModal";

const ProspectTableModal = ({
  sequences,
  influencers,
  userEmails,
  prospectData,
  companyID,
  companyName,
  fetchCompanyData,
  handleRowClick,
  setUpdateProspect,
  onCloseProspect,
  setIsOpenProspectModal,
  updateProspect,
  setTargetProspectId,
  setUpdateEmail,
  updateEmail,
  setProspectDelete,
  setProspectData,
}) => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const columns = useMemo(() => PROSPECT_COLUMNS, []);
  const [data, setData] = useState(prospectData);

  // const deletedData =data;

  const [prospectUpDelete, setProspectUpDelete] = useState(false);

  const [isOpenProspectFormModal, setIsOpenProspectFormModal] = useState(false);
  const [isOpenLinkedInModal, setIsOpenLinkedInModal] = useState(false);

  // const [isOpenEmailsFormModel, setIsOpenEmialsFormModel] = useState(false);
  const [isOpenProspectDeleteDialogBox, setIsOpenProspectDeleteDialogBox] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] =
    useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [flat, setFlat] = useState(null);
  const [Final, setFinal] = useState([]);

  const [verifyemails, setVerifyEmails] = useState([]);

  const [isOpenEmailsModel, setIsOpenEmailsModel] = useState(false);
  // const [prospectsData, setProspectsData] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    selectedFlatRows,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const handleDeleteRows = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      const prospectIds = selectedFlatRows.map(
        (row) => row?.original?.prospect_id
      );

      const response = await fetch(BASE_URL + "/prospect/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ prospect_ids: prospectIds }),
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }

      if (response.ok) {
        setIsLoading(false);
        setData((prevData) =>
          prevData.filter(
            (prospect) => !prospectIds.includes(prospect.prospect_id)
          )
        );
        setProspectData((prevData) =>
          prevData.filter(
            (prospect) => !prospectIds.includes(prospect.prospect_id)
          )
        );

        // setProspectUpDelete(true)
        setProspectDelete(true);
        toast({
          title: "Prospects  Deleted",
          description: "Prospects  deleted successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenProspectDeleteDialogBox(false);
        fetchCompanyData();
      } else {
        setIsLoading(false);

        toast({
          title: "Deletion Failed",
          description: "Failed to delete prospects details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete prospects details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleEditRow = () => {
    const selectedEditableProspects = selectedFlatRows?.map(
      (row) => row.original.prospect_id
    );
    setIsEditing(selectedEditableProspects);
    // setIsEditing(selectedFlatRows[0]?.original?.prospect_id);
    // setEditedRowData(selectedFlatRows[0]?.original);
    const selectedRowData = selectedFlatRows?.map((row) => row.original);
    setEditedRowData(selectedRowData);
  };

  const handleVerifyEmails = async () => {
    const payload = {
      prospect_id: selectedFlatRows[0]?.original.prospect_id,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/show-verify-email/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.length > 0) {
        setVerifyEmails(response.data);
      } else {
        setVerifyEmails([]);
      }

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const hadnleClickEmails = () => {
    setUpdateEmail(false);

    setIsOpenEmailsModel(true);

    setFlat(selectedFlatRows[0]?.original.prospect_id);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      prospects_data: [
        {
          first_name: selectedFlatRows[0]?.original.first_name,
          last_name: selectedFlatRows[0]?.original.last_name,
          company: selectedFlatRows[0]?.original.company,
        },
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/prospect-emails/`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        return response.json();
      })
      .then((result) => {
        setFinal(result);
        handleVerifyEmails();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // handleEditRow()
    setIsEditing(null);
    setEditedRowData(null);
  }, [selectedFlatRows]);

  useEffect(() => {
    if (updateProspect || !isOpenProspectFormModal) {
      setData(prospectData);
      fetchCompanyData();
    }
  }, [updateProspect, !isOpenProspectFormModal]);

  useEffect(() => {
    if (!updateProspect) {
      setData(prospectData);
      fetchCompanyData();
    }
  }, [!updateProspect]);

  useEffect(() => {
    if (!isOpenEmailsModel) {
      setData(prospectData);
      fetchCompanyData();
    }
  }, [!isOpenEmailsModel]);

  useEffect(() => {
    setData(prospectData);
  }, [prospectData]);

  const handleCreateCampaignClick = () => {
    setIsCreateCampaignModalOpen(true);
  };

  const handleSaveRow = async () => {
    const payload = {
      prospect_data: editedRowData,
    };

    try {
      const response = await fetch(BASE_URL + `/updateprospects/`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);

        toast({
          title: "Prospect Updated",
          description: "Prospect updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        // Now, uncheck all selected checkboxes
        rows.forEach((row) => {
          toggleRowSelected(row.id, false);
        });

        const jsonResponse = await response.json();
        setData((prevProspectData) => {
          const updatedProspectsMap = new Map(
            jsonResponse?.updated_data?.map((p) => [p.prospect_id, p])
          );
          const newProspectData = prevProspectData.map((p) =>
            updatedProspectsMap.has(p.prospect_id)
              ? updatedProspectsMap.get(p.prospect_id)
              : p
          );
          return newProspectData;
        });
        setProspectData((prevProspectData) => {
          const updatedProspectsMap = new Map(
            jsonResponse?.updated_data?.map((p) => [p.prospect_id, p])
          );
          const newProspectData = prevProspectData.map((p) =>
            updatedProspectsMap.has(p.prospect_id)
              ? updatedProspectsMap.get(p.prospect_id)
              : p
          );
          return newProspectData;
        });
      } else {
        throw new Error("Failed to update prospect details.");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update prospect details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleProspectClick = () => {
    setIsOpenProspectFormModal(true);

    setUpdateProspect(false);
    fetchCompanyData();
  };

  const handleLinkedInClink = () => {
    setIsOpenLinkedInModal(true);
  };

  return (
    <Box>
      <EmailsModel
        isOpen={isOpenEmailsModel}
        onClose={() => {
          setIsOpenEmailsModel(false);
          //  setFlat(null)
          //  setFinal([])
          fetchCompanyData();

          //  fetchCompanyData();
        }}
        EmailsData={Final}
        prospect_id={flat}
        prospectData={prospectData}
        verifyemails={verifyemails}
        handleVerifyEmails={handleVerifyEmails}
        hadnleClickEmails={hadnleClickEmails}
        fetchCompanyData={fetchCompanyData}
        handleRowClick={handleRowClick}
        setData={setData}
        setUpdateProspect={setUpdateProspect}
        setTargetProspectId={setTargetProspectId}
        setUpdateEmail={setUpdateEmail}
        onCloseProspect={onCloseProspect}
        //    selectedPropspectsTable={selectedFlatRows}

        //    SaveData= {fetchProspectData}
      />
      {selectedFlatRows.length > 0 ? (
        <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
          <Box>{selectedFlatRows.length} selected</Box>
          <Flex alignItems={"center"} gap={3}>
            <Button
              onClick={handleCreateCampaignClick}
              size={"sm"}
              colorScheme="blue"
              variant="solid"
            >
              <BsFillSendPlusFill size={20} color="white" />
            </Button>

            {selectedFlatRows.length === 1 ? (
              <Button
                onClick={hadnleClickEmails}
                size={"sm"}
                colorScheme="blue"
                variant="solid"
              >
                <TbMailSearch size={25} color="white" />
              </Button>
            ) : (
              " "
            )}
            {isEditing !== null ? (
              <Button onClick={handleSaveRow} size={"sm"} colorScheme="blue">
                Save
              </Button>
            ) : (
              <Button onClick={handleEditRow} size={"sm"} colorScheme="yellow">
                Edit
              </Button>
            )}
            <Button
              onClick={() => setIsOpenProspectDeleteDialogBox(true)}
              colorScheme="red"
              variant="solid"
            >
              <IoMdTrash size={20} color="white" />
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Button
            mb={3}
            onClick={handleProspectClick}
            size={"md"}
            colorScheme="blue"
            variant="solid"
          >
            <FaUserPlus size={23} />
          </Button>
          <Button
            mb={3}
            ml={3}
            onClick={handleLinkedInClink}
            size={"md"}
            colorScheme="blue"
            variant="solid"
          >
            <Img src={linkedInIcon} alt={linkedInIcon} w={23} h={23} />
          </Button>
        </>
      )}
      <Box
        fontSize={14}
        mb={1}
        boxShadow="xs"
        h={"400px"}
        rounded={"md"}
        sx={{
          position: "relative",
        }}
      >
        <TableContainer
          {...getTableProps()}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: "scroll",
            "&::-webkit-scrollbar": { height: "10px", width: "0px" },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#3182ce",
              borderRadius: "10px",
            },
          }}
        >
          <Table variant="simple" width={"100%"}>
            <Thead bg={"green.400"}>
              {headerGroups?.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                      <Flex alignItems={"center"}>
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows?.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    _hover={{
                      bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    }}
                  >
                    {row.cells.map((cell) => {
                      // const isRowEditing = isEditing === row?.original?.prospect_id;
                      const isRowEditing = isEditing?.includes(
                        row?.original?.prospect_id
                      );
                      return (
                        <Td {...cell.getCellProps()}>
                          {cell.column.id === "selection" ? (
                            <Checkbox {...row.getToggleRowSelectedProps()} />
                          ) : cell.column.Header === "Created At" ? (
                            format(
                              new Date(row.original.created_ts),
                              "MM/dd/yyyy HH:mm:ss"
                            )
                          ) : isRowEditing ? (
                            <Textarea
                              style={{ resize: "both", minWidth: "250px" }}
                              // w="250px"
                              type="text"
                              value={row.values[cell.column.id]}
                              // onChange={(e) => {
                              //     const fieldName = cell.column.id;
                              //     row.values[fieldName] = e.target.value;
                              //     setEditedRowData({ ...editedRowData, [fieldName]: e.target.value });
                              // }}
                              onChange={(e) => {
                                const fieldName = cell.column.id;
                                const updatedValue = e.target.value;
                                const updatedProspectId =
                                  row.original.prospect_id;
                                row.values[fieldName] = updatedValue;
                                // Create a new array with updated data for the corresponding prospect
                                const updatedEditedRowData = editedRowData?.map(
                                  (rowData) => {
                                    if (
                                      rowData.prospect_id === updatedProspectId
                                    ) {
                                      return {
                                        ...rowData,
                                        [fieldName]: updatedValue,
                                      };
                                    }
                                    return rowData;
                                  }
                                );

                                setEditedRowData(updatedEditedRowData);
                              }}
                            />
                          ) : (
                            cell.render("Cell")
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <CreateCampaignModal
        sequences={sequences}
        emailList={userEmails}
        influencers={influencers}
        selectedPropspects={selectedFlatRows}
        isOpen={isCreateCampaignModalOpen}
        setIsCreateCampaignModalOpen={setIsCreateCampaignModalOpen}

        // onClose={() => {
        // setIsCreateCampaignModalOpen(false);
        // }}
      />

      <ProspectFormModal
        isOpen={isOpenProspectFormModal}
        onClose={() => {
          setIsOpenProspectFormModal(false);
          // setProspectUpDelete(true)
          // fetchCompanyData();
        }}
        companyID={companyID}
        companyName={companyName}
        setUpdateProspect={setUpdateProspect}
        fetchCompanyData={fetchCompanyData}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        onCloseProspect={onCloseProspect}
        setIsOpenProspectModal={setIsOpenProspectModal}
        setTargetProspectId={setTargetProspectId}
        setProspectUpDelete={setProspectUpDelete}
        prospectDataform={prospectData}
        setData={setData}
        setProspectData={setProspectData}
      />

      <ProspectLinkedInFormModal
        isOpen={isOpenLinkedInModal}
        onClose={async () => {
          setIsOpenLinkedInModal(false);
          await fetchCompanyData(true);
        }}
        companyID={companyID}
      />

      <ProspectDeleteDialogBox
        isOpen={isOpenProspectDeleteDialogBox}
        onClose={() => {
          setIsOpenProspectDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />
    </Box>
  );
};

export default ProspectTableModal;
