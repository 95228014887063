import {
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { AddEmailModal } from "./AddEmailModal";
import { FaPaperPlane, FaTelegramPlane } from "react-icons/fa";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { TemplateEditorModal } from "./TemplateEditorModal";
import DelayTooltipModal from "./DelayTooltipModal";
import { BiSolidDownArrow } from "react-icons/bi";
import { SequenceMeta } from "./SequenceMeta";
import { IoInformationCircleOutline } from "react-icons/io5";
// import { isDisabled } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";

export const SequenceDetails = ({
  isDisabled,
  fetchSequences,
  selectedSequence,
  // responseSeq_Id,
  showSequenceDetails,
  setShowSequenceDetails,
  sequenceCopyId,
  setSequenceCopyId,
}) => {
  const [sequence, setSequence] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [jobLists, setJobLists] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);
  const [selectedJob, setSeletedJob] = useState();
  const [autoSend, setAutoSend] = useState(false);
  const [isTemplateEditorModalOpen, setIsTemplateEditorModalOpen] =
    useState(false);
  // const [isCreateNewTemplate, setIsCreateNewTemplate] = useState(false);

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const toast = useToast();
  const navigate = useNavigate();

  // const addEmails = (newEmail) => {
  //   setJobLists((prev) => [...prev, newEmail]);
  // };

  const fetchTemplates = async () => {
    try {
      const response = await fetch(BASE_URL + `/alltemplate/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const templatesData = await response.json();
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        const sortedTemplates = templatesData["Email Templates"].sort(
          (a, b) => b.created_ts - a.created_ts
        );
        setEmailTemplates(sortedTemplates);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePlusClick = (job) => {
    setSeletedJob(job);
    fetchTemplates();
    setIsAddEmailModalOpen(true);
  };
  const handleAddFirstJob = () => {
    // setSeletedJob(job);
    fetchTemplates();
    setIsAddEmailModalOpen(true);
  };

  const fetchSequence = async () => {
    let sequencefetchId = selectedSequence.id;

    setIsLoading(true);
    try {
      const response = await fetch(BASE_URL + `/sequence/${sequencefetchId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        setIsLoading(false);

        const jobs = data.Seqeunce.jobs.sort((a, b) => a.job_no - b.job_no);
        let totalDelay = 0;
        const jobsWithDay = jobs.map((job) => {
          totalDelay += job.delay_time; // Update the total delay for the next job
          const newJob = { ...job };
          newJob.day = totalDelay; // Set the "day" key to the current total delay
          return newJob;
        });
        // setSequenceCopyId('')
        // setShowSequenceDetails(false)
        setJobLists(jobsWithDay);
        // setJobLists(jobs)
        setSequence(data.Seqeunce);
        setAutoSend(data.Seqeunce.auto_send);
      }
    } catch (error) {
      console.log('error: ', error);
      
      toast({
        title: "sequences",
        description: "Failed to get details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSequence();
  }, [selectedSequence.id]);

  const handleJobClick = (job) => {
    setSeletedJob(job);
    setIsTemplateEditorModalOpen(true);
  };

  const toPlainText = (htmlString) => {
    // Create a temporary HTML element to parse the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    // Extract the plain text from the temporary element
    const plainText = tempElement.textContent;
    // Replace consecutive whitespace characters with a single space
    const formattedText = plainText.replace(/\s+/g, " ");
    // Remove leading and trailing whitespaces
    const trimmedPlainText = formattedText.trim();
    return trimmedPlainText;
  };

  // job container
  const JobContainer = ({ job, index }) => {
    const handleDeleteJob = async () => {
      const payload = {
        job_id: job.id,
        sequence_id: selectedSequence.id,
        job_no: job.job_no,
      };
      try {
        const response = await fetch(BASE_URL + `/delete-job/`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
        const res = await response.json();
        // setIsUpdating(false)
        // setIsEditable(!isEditable);
        if (response.ok) {
          toast({
            title: "Job",
            description: "Successfully deleted",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          fetchSequence();
        }
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        // setIsLoading(false);
        toast({
          title: "Fetch Error.",
          description: "Failed to delete the job",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      }
    };
    // for tooltip modal
    const [isOpen, setIsOpen] = useState(false);

    const toggleTooltip = () => {
      setIsOpen(!isOpen);
    };

    return (
      <Box mx={3}>
        <Box display="inline-block" position="relative">
          <Text fontSize={"sm"} mb={1}>
            Delay:{" "}
            {/* <Button>

            </Button> */}
            <Text
              fontSize={"sm"}
              onClick={toggleTooltip}
              cursor={"pointer"}
              as={"b"}
              textColor={"teal.500"}
              _hover={{
                filter: "drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))",
                textDecoration: "underline",
              }}
            >
              {job.delay_time === 0 ? "None" : `${job.delay_time} business day`}
            </Text>
          </Text>
          {!isDisabled && (
            <DelayTooltipModal
              isOpen={isOpen}
              job={job}
              fetchSequence={fetchSequence}
              onClose={() => setIsOpen(!isOpen)}
            />
          )}
        </Box>
        <Flex
          p={3}
          gap={2}
          boxShadow={"0 2px 4px rgba(0, 0, 0, 0.1)"}
          bgColor={"white"}
          justifyContent={"space-between"}
          borderRadius={"5px"}
          // onClick={handleJobClick}
        >
          <Flex gap={2}>
            <Circle
              // alignSelf={"center"}
              size="35px"
              border={"1px solid tomato"}
              color="tomato"
            >
              <FaPaperPlane />
            </Circle>
            <Box>
              <Text color={"gray.500"}>
                {job.job_no}. {job.type} - Day {job.day}
              </Text>
              <Text as={"b"} fontSize={"sm"}>
                {job.email_template.subject}
              </Text>
              <Text fontSize={"sm"} noOfLines={1}>
                {toPlainText(job.email_template.body)}
              </Text>
            </Box>
          </Flex>
          <Box display={"flex"} gap={2}>
            <IconButton
              borderRadius={"50%"}
              size={"sm"}
              icon={
                <Icon
                  boxSize={5}
                  color={"gray.400"}
                  _hover={{ color: "green" }}
                  as={MdRemoveRedEye}
                />
              }
              onClick={() => handleJobClick(job)}
            />
            <IconButton
              isDisabled={isDisabled}
              borderRadius={"50%"}
              size={"sm"}
              icon={
                <Icon
                  boxSize={5}
                  color={"gray.400"}
                  _hover={{ color: "red" }}
                  as={MdDelete}
                />
              }
              onClick={handleDeleteJob}
            />
          </Box>
        </Flex>
        <VStack my={1}>
          <Divider orientation="vertical" height="15px" opacity={"1"} />
          <IconButton
            isDisabled={isDisabled}
            borderRadius={"50%"}
            size={"sm"}
            icon={
              <Icon
                boxSize={5}
                color={"gray.400"}
                _hover={{ color: "teal" }}
                as={AiOutlinePlusSquare}
              />
            }
            onClick={() => handlePlusClick(job)}
          />
          <Divider orientation="vertical" height="15px" opacity={"1"} />
          <BiSolidDownArrow color="gray" size={10} />
        </VStack>
      </Box>
    );
  };

  return (
    // <Box>
    <Box
    // m={"auto"}
    // h={"100%"}
    // overflow={"auto"}
    >
      <Box
        borderRadius={"0 0.375rem 0 0"}
        p={3}
        mb={4}
        bgColor={"white"}
        boxShadow={"0 4px 4px rgba(0, 0, 0, 0.2) "}
      >
        {selectedSequence && (
          <SequenceMeta
            isDisabled={isDisabled}
            fetchSequences={fetchSequences}
            sequence={selectedSequence}
            autoSend={autoSend}
            setAutoSend={setAutoSend}
          />
        )}
      </Box>
      {/* <Box
          w={"60vw"}
          p={4}
          m={"auto"}
          my={3}
          bgColor={"white"}
          boxShadow={"0 2px 4px rgba(0, 0, 0, 0.1)"}
        >
          <Text as={"b"}>Sequence Summary</Text>

          <HStack m={3} justifyContent={"center"} gap={2}>
            <Box py={5} px={3} justifyContent={"center"}>
              <Text fontSize={"lg"}>Total Steps</Text>
              <Text textAlign={"center"} fontSize={"lg"}>
                0
              </Text>
            </Box>
            <Box py={5} px={3}>
              <Text fontSize={"lg"}>Days To Complete</Text>
              <Text textAlign={"center"} fontSize={"lg"}>
                1
              </Text>
            </Box>
            <Box py={5} px={3}>
              <Text fontSize={"lg"}>Automation</Text>
              <Text textAlign={"center"} fontSize={"lg"}>
                0 %
              </Text>
            </Box>
            <Box py={5} px={3}>
              <Text fontSize={"lg"}>Unenroll Criteria</Text>
              <Text textAlign={"center"} fontSize={"lg"}>
                2
              </Text>
            </Box>
            <Box></Box>
          </HStack>
        </Box> */}
      {/* timeline */}

      <Box
        maxH={"calc(100vh - 190px)"}
        overflow={"auto"}
        sx={{
          "&::-webkit-scrollbar": { width: "5px" },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#38A169",
            borderRadius: "10px",
          },
        }}
      >
        {sequence?.jobs && sequence?.jobs.length > 0 ? (
          // sequence?.jobs.map((job, index) => {
          jobLists?.map((job, index) => {
            return (
              <SkeletonText
                key={job.id}
                h={"fit-content"}
                noOfLines={3}
                fadeDuration={1}
                isLoaded={!isLoading}
              >
                <JobContainer job={job} index={index} />
              </SkeletonText>
            );
          })
        ) : (
          <>
            {/* <JobContainer job={job} index={0} /> */}
            <Flex
              alignItems={"center"}
              gap={2}
              // w={"60%"}
              w={"550px"}
              p={2}
              m={"auto"}
              mt={3}
              bgColor={"white"}
              boxShadow={"0 2px 4px rgba(0, 0, 0, 0.1)"}
            >
              <IoInformationCircleOutline color="#38A169" size={20} />
              <Text>
                Get started by adding an email template or task to your new
                sequence
              </Text>
            </Flex>
            <VStack my={1}>
              <Divider orientation="vertical" height="15px" />
              <IconButton
                isDisabled={isDisabled}
                borderRadius={"50%"}
                size={"sm"}
                icon={
                  <Icon
                    boxSize={5}
                    color={"gray.400"}
                    _hover={{ color: "teal" }}
                    as={AiOutlinePlusSquare}
                  />
                }
                onClick={handleAddFirstJob}
              />
              <Divider orientation="vertical" height="15px" />
              <BiSolidDownArrow color="gray" size={10} />
            </VStack>
          </>
        )}
      </Box>

      <AddEmailModal
        isOpen={isAddEmailModalOpen}
        sequenceId={selectedSequence.id}
        emailTemplates={emailTemplates}
        fetchTemplates={fetchTemplates}
        fetchSequence={fetchSequence}
        selectedJob={selectedJob}
        setEmailTemplates={setEmailTemplates}
        // emailTemplates={emailTemplates}
        // setTemplateEditorOpen = {setIsTemplateEditorModalOpen}
        // setIsCreateNewTemplate = {setIsCreateNewTemplate}
        // addEmails={addEmails}
        onClose={() => {
          setIsAddEmailModalOpen(false);
          // fetchSequence();
        }}
      />
      {selectedJob && (
        <TemplateEditorModal
          isDisabled={isDisabled}
          isOpen={isTemplateEditorModalOpen}
          template={selectedJob?.email_template}
          // isCreateNewTemplate = {isCreateNewTemplate}
          fetchSequence={fetchSequence}
          fetchTemplates={fetchTemplates}
          onClose={() => {
            setIsTemplateEditorModalOpen(false);
            // setIsCreateNewTemplate(false);
          }}
        />
      )}
    </Box>
    // </Box>
  );
};
