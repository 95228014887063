import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { SequenceDetails } from "./SequenceDetails";
import { SequenceSetting } from "./SequenceSetting";
import { SequenceAutomation } from "./SequenceAutomation";

export const NewSequences = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [sequence, setSequence] = useState({
    id: location?.state?.sequence_id ? location?.state?.sequence_id : "",
    name: location?.state?.name ? location?.state?.name : "",
    description: location?.state?.description
      ? location?.state?.description
      : "",
  });

  // const [sequenceId, setSequenceId] = useState(location.state.sequence_id);
  // const [sequenceName, setSequenceName] = useState(location.state.name);
  // const [sequenceDescription, setSequenceDescription] = useState(
  //   location.state.description
  // );

  // const navList = ["Steps", "Setting", "Automation"];
  const navList = ["Steps"]

  const [selectedNav, setSelectedNav] = useState(navList[0]);

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  //   const handleSequenceNameInput = () => {};

  return (
    <Box h={"calc(100vh - 60px)"} bgColor={"gray.50"}>
      {/* input nav */}
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"50px"}
        bgColor={"#263238"}
        boxShadow={"0 4px 4px rgba(0, 0, 0, 0.4)"}
      >
        <Button
          leftIcon={<IoIosArrowBack />}
          onClick={() => navigate(-1)}
          colorScheme="blackAlpha"
          // bgColor={'#263238'}
          color={"white"}
          h={"100%"}
          borderRadius={0}
        >
          Back
        </Button>

        {/* <Box>
          <Button isDisabled={jobLists.length < 1} colorScheme={"blackAlpha"}>
            Save
          </Button>
        </Box> */}
      </Flex>
      {/* steps and setting nav */}
      <Flex h={"50px"} justifyContent={"center"} gap={"20px"} bgColor={"white"}>
        {navList.map((nav, index) => {
          return (
            <Box
              p={3}
              py={2}
              onClick={() => setSelectedNav(nav)}
              //   bgColor={selectedNav === nav ? "teal.400" : ""}
              border={selectedNav === nav ? "4px soild green" : ""}
              borderBottom={selectedNav === nav ? "4px" : ""}
              _hover={{ cursor: "pointer" }}
            >
              <Text as={"b"}>{nav}</Text>
            </Box>
          );
        })}
      </Flex>
      <Box
        px={4}
        mx={"auto"}
        h={"calc(100vh - 160px)"}
        overflow={"auto"}
        sx={{
          "&::-webkit-scrollbar": { width: "5px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
          scrollbarWidth: "5px",
        }}
      >
        <Box mx={"auto"} w={"80%"} p={5}>
          {selectedNav === navList[0] && (
            <SequenceDetails selectedSequence={sequence} />
          )}
          {selectedNav === navList[1] && (
            <SequenceSetting selectedSequence={sequence} />
          )}
          {selectedNav === navList[2] && (
            <SequenceAutomation selectedSequence={sequence} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
