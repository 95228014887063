import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Button,
} from "@chakra-ui/react";
// import { color } from 'framer-motion';
import { useEffect } from "react";
import { Box, Flex, useColorMode, useToast } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdMarkEmailRead } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EmailsModel = ({
  isOpen,
  onClose,
  EmailsData,
  prospect_id,
  SaveData,
  setData,
  prospectData,
  verifyemails,
  handleVerifyEmails,
  hadnleClickEmails,
  fetchCompanyData,
  setUpdateProspect,
  setTargetProspectId,
  setUpdateEmail,
  onCloseProspect,
  selectedFlatRows,
  setProspectUpDelete,
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const emails = EmailsData?.Format_emails ?? [];
  const [show, setShow] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [data, setDataEmail] = useState(null);

  const { colorMode } = useColorMode();
  const [selections, setSelections] = useState(null);
  const [selectedRow, setSelectedRow] = useState(); // Track the selected row's identifier/index
  const toast = useToast();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [individualEmail, setIndividualEmail] = useState();

  const [editableColum, setEditableColumn] = useState(null);
  const [newArray, setNewArray] = useState([]);

  const handleUseEmail = () => {
    fetchCompanyData();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      prospect_id: prospect_id,
      email: individualEmail,
      email_validity: selections,
    });

    // setTargetProspectId(prospect_id);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/prospect-update-emails/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTargetProspectId(prospect_id);
        setUpdateEmail(true);

        toast({
          title: "User Email updated",
          description: " User Email Updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        // onClose()
        // SaveData();

        setSelections(null);
        setSelectedRow();

        setTimeout(() => {
          onClose();
          setSelectedEmails([]);
        }, 1000);
      })

      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }
      });
  };

  useEffect(() => {
    // handleRowClick('email');
    // setSelectedRow(null);
  }, [prospect_id]);

  const handleMarkMail = async () => {
    const payload = {
      prospect_id: prospect_id,
      emails: selectedEmails,
    };
    try {
      const response = await axios.post(`${BASE_URL}/verify-email/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if ((response.statusText = "OK")) {
        hadnleClickEmails();
        setTimeout(() => {
          setSelectedEmails([]);
        }, 1000);
       
        toast({
          title: "User Email verify.",
          description:`${response?.data?.message || "User Email verify  successfully."}`,
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      toast({
        title: "Something went wrong.",
        description: " Something went wrong.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAllChecked = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const allEmails = emails.map((emailData) => emailData.email);
      setSelectedEmails(allEmails);
    } else {
      setSelectedEmails([]);
    }
  };

  const handleIndividualCheckbox = (status, email, checked) => {
    setSelections(status);

    setSelectedEmails((prev) => {
      const updatedSelectedEmails = checked
        ? [...prev, email]
        : prev.filter((e) => e !== email);
      if (updatedSelectedEmails.length === 1) {
        setIndividualEmail(updatedSelectedEmails[0]);
      } else {
        setIndividualEmail(null);
      }
      return updatedSelectedEmails;
    });
  };

  useEffect(() => {
    const updatedArray =
      EmailsData &&
      EmailsData?.Format_emails?.map((secondItem) => {
        const matchingItem =
          verifyemails &&
          verifyemails.find(
            (firstItem) => firstItem.email === secondItem.email
          );
        if (matchingItem) {
          const createdDate = new Date(matchingItem.created_ts);
          const formattedDate = createdDate
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "-");
          const formattedTime = createdDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return {
            email: secondItem.email,
            status: matchingItem.status,
            created_ts: `${formattedDate} ${formattedTime}`,
            percentage: secondItem.percentage,
          };
        } else {
          return {
            email: secondItem.email,
            status: "",
            created_ts: "",
            percentage: secondItem.percentage,
          };
        }
      });
    setNewArray(updatedArray || []);
  }, [EmailsData, verifyemails]);

  const handleEmptyState = () => {
    setSelectedEmails([]);
    setIndividualEmail();
  };

  const handleColumnClick = (email, status) => {
    setEditableColumn(email);
    setSelections(status);
  };

  const handleCellBlur = (email, status) => {
    const updatedArray = newArray.map((item) => {
      if (item.email === email) {
        return { ...item, status: status };
      }
      return item;
    });

    setNewArray(updatedArray);
    setEditableColumn(null);

    handleSaveColum(email, status);
  };

  const handleSaveColum = async (email, status) => {
    const payload = {
      prospect_id: prospect_id,
      email: email,
      status: status,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/update_status_verify_email/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if ((response.statusText = "OK")) {
        toast({
          title: "Update Status Verify Emails.",
          description: "Update Status of Verify Emails Succesfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      toast({
        title: "Something went wrong.",
        description: " Something went wrong.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={"500"} fontSize={"2xl"}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box fontWeight="500" fontSize="xl" minH={'32px'}>
              Select Email Address
            </Box>
            <Flex justifyContent="flex-end" mr={7}>
              {selectedEmails.length > 0 && (
                <Button
                  colorScheme="blue"
                  onClick={handleMarkMail}
                  size={"sm"}
                >
                  <RiVerifiedBadgeFill size={23} color="white" />
                </Button>
              )}
              {selectedEmails.length === 1 && (
                <Button
                  colorScheme="green"
                  ml={3}
                  size={"sm"}
                  onClick={() => {
                    if (selections !== null) {
                      handleUseEmail();
                    }
                  }}
                >
                  <MdMarkEmailRead size={23} color="white" />
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="Red" onClick={handleEmptyState} />
        <ModalBody>
          <Box
            fontSize={14}
            mb={1}
            boxShadow="xs"
            rounded={"md"}
            sx={{
              position: "relative",
            }}
          >
            <Table variant="simple">
              <Thead bg={"green.400"}>
                <Tr>
                  <Th color={"#FFFFFF"}>
                    <input
                      type="checkbox"
                      onChange={handleAllChecked}
                      checked={
                        selectedEmails.length === emails.length &&
                        emails.length > 0
                      }
                      indeterminate={
                        selectedEmails.length > 0 &&
                        selectedEmails.length < emails.length
                      }
                    ></input>
                  </Th>
                  <Th color={"#FFFFFF"}>Emails</Th>
                  <Th color={"#FFFFFF"}>Usage</Th>
                  <Th color={"#FFFFFF"}>Emails Validity</Th>
                  <Th color={"#FFFFFF"}>Verified</Th>
                </Tr>
              </Thead>
              <Tbody>
                {newArray &&
                  newArray.map((value, index) => {
                    return (
                      <Tr
                        _hover={{
                          bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        <Td>
                          <input
                            type="checkbox"
                            checked={selectedEmails.includes(value.email)}
                            onChange={(e) =>
                              handleIndividualCheckbox(
                                value.status,
                                value.email,
                                e.target.checked
                              )
                            }
                          ></input>
                        </Td>
                        <Td>{value.email}</Td>
                        <Td>{value.percentage}</Td>
                        <Td
                          onClick={() =>
                            handleColumnClick(value.email, value.status)
                          }
                        >
                          {editableColum == value.email ? (
                            <textarea
                              value={selections}
                              onChange={(e) => setSelections(e.target.value)}
                              onBlur={() =>
                                handleCellBlur(value.email, selections)
                              }
                            />
                          ) : (
                            value.status
                          )}
                        </Td>
                        <Td>{value.created_ts}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailsModel;
