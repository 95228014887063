import React, {
  useEffect,
  useMemo,
  useState,
  memo,
  createRef,
  forwardRef,
} from "react";
import {
  ALL_DEALS_COLUMNS,
  RELEVANT_INFLUENCERS_DEALS_COLUMNS,
} from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Wrap,
} from "@chakra-ui/react";
import { fetchInfluencerMetaData } from "../../services/influencerApi";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { CloseIcon, SearchIcon, AddIcon } from "@chakra-ui/icons";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Checkbox1 from "./Checkbox";
import { IoMdTrash, IoIosArrowBack } from "react-icons/io";
import { format, parse, isValid, setDate } from "date-fns";
import { defer, useNavigate } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import ChakraDatePicker from "../../utils/ChakraDatePicker";
import { useLocation } from "react-router-dom";
import {
  MenuButton,
  Menu,
  MenuList,
  MenuGroup,
  Tag,
  Checkbox,
  Spinner,
  Card,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { BsFiletypeCsv, BsFilter, BsBuildingFillAdd } from "react-icons/bs";
import DealsDeleteDialogBox from "../Modal/DealsDeleteDialogBox";
import { PulseLoader } from "react-spinners";
import { TbPencilDollar } from "react-icons/tb";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../Table/datepicker.css";
import CompanyFormModal from "../Modal/CompanyFormModal";
import CreateProspectModal from "../Modal/CreateProspectModal";
import InfluencerMailModel from "../Modal/InfluencerMailModel";
import DealsFollowConfirmModel from "../Modal/DealsFollowConfirmModel";
import { RiMailSendFill } from "react-icons/ri";
import { FaRegClock } from "react-icons/fa";
import ClockModel from "../Modal/ClockModel";
import { FaFileInvoiceDollar } from "react-icons/fa";
import axios from "axios";
import Invoice from "./Invoice";
import { FaFileContract } from "react-icons/fa";
import "../Table/links.css";
import { FaUpload } from "react-icons/fa";
import { FaFileCircleXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
{
  /* <FaRegClock /> */
}

// const MemoKol = memo(({ setPrintButton }) => (
//   <Invoice  setPrintButton={setPrintButton} />
//   //<KolProfilePrintOld {...props} setPrintButton={setPrintButton} setbutton={setbutton}  setbutton2={setbutton2}/>
// ));

const AllDealsTab = () => {
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const columns = useMemo(() => {
    if (role === "Influencer") {
      return RELEVANT_INFLUENCERS_DEALS_COLUMNS || [];
    } else if (role === "Admin") {
      return ALL_DEALS_COLUMNS || [];
    } else if (role === "Creator Manager") {
      return ALL_DEALS_COLUMNS || [];
    }
  }, [role]);

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  // const { id, tal_name } = location.state || {};

  function getInfluencersId() {
    const path = window.location.pathname.split("/");
    const influencer_Ids = path[path.length - 1];
    return influencer_Ids;
  }

  const [influenserId, setInfluencerId] = useState();

  // const columns = useMemo(() => ALL_DEALS_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [influencerData, setInfluencerData] = useState([]);

  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStage, setselectedStage] = useState([]);

  const [inputTalentValue, setInputTalentValue] = useState("");
  const [selectedTalents, setSelectedTalents] = useState([]);
  const [isTalentSearchLoading, setIsTalentSearchLoading] = useState(false);
  const [filteredTalentData, setFilteredTalentData] = useState([]);

  const [inputInvoiceValue, setInputInvoiceValue] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [isInvoiceSearchLoading, setIsInvoiceSearchLoading] = useState(false);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [inputCompanyValue, setInputCompanyValue] = useState("");
  const [isCompanySearchLoading, setIsCompanySearchLoading] = useState(false);
  const [filteredCompanyData, setFilteredCompanyData] = useState([]);

  const [selectedCreator, setSelectedCreator] = useState([]);
  const [inputCreatorValue, setInputCreatorValue] = useState("");

  const [selectedMissing, setselectedMissing] = useState([]);
  const [selectedStartdate, SetSelectedStartDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEnddate, SetSelectedEndDate] = useState(null);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isOpenDealsDeleteDialogBox, setIsOpenDealsDeleteDialogBox] =
    useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [dealsId, setDealsId] = useState();
  const previousRowDataRef = useRef({});
  const [influencerRerender, setInfluencerRerender] = useState(false);
  const [intCompanyName, setIntCompanyName] = useState(null);
  const [backPage, setBackPage] = useState(null);
  const [stateChange, setStateChange] = useState(false);
  const [brandRep, setBrandRep] = useState([]);
  const [isOpenCompanyFormModal, setIsOpenCompanyFormModal] = useState(false);
  const [isOpenProspectFormModal, setIsOpenProspectFormModal] = useState(false);
  const [isOpenInfluencerMailModel, setIsOpenInfluencerMailModel] =
    useState(false);
  const [isOpenDealsFollowConfirmModel, setisOpenDealsFollowConfirmModel] =
    useState(false);
  const [rowData, setRowData] = useState("");
  const [Icon, SetIcon] = useState("");
  const [isOpenClockModel, setIsOpenClockModel] = useState(false);
  const [clockDate, setClockDate] = useState("");
  const [DeleteFollowId, setDeleteFollowId] = useState("");
  const [printButton, setPrintButton] = useState(false);

  const [isOpenInvoice, setisOpenInvoice] = useState(false);
  const [talent_name, setTalentName] = useState("");
  const [brandForInvoice, setbrandForInvoice] = useState("");
  const [DealAmountForInvoice, setDealAmountForInvoice] = useState("");
  const [BrandRepForInvoice, setBrandRepForInvoice] = useState();
  const [DeliverablsForInvoice, setDeliverablsForInvoice] = useState();
  const [LinkForInvoice, setliveLinkForInvoice] = useState();
  const [LoctionForInvoice, setLoctionForInvoice] = useState("");
  const [MailForInvoice, setMailForInvoice] = useState();
  const [InvoiceForInvoice, setInvoiceForInvoice] = useState("");
  const [DealIdForInvoice, setDealIdForInvoice] = useState("");
  const [InvoiceDate, setInvoiceDate] = useState(null);
  const [PaymentTermForInvoice, setPaymentTermForInvoice] = useState("");
  const [IdPart, setIdPart] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [AllCreatorsName, setAllCreatorsName] = useState([]);
  const [activeDealsTab, setActiveDealsTab] = useState(false);
  const [roles, setRoles] = useState(role);

  const theme = extendTheme({
    colors: {
      custom: {
        black: "#000000",
        white: "#FFFFFF",
      },
    },
  });
  const getInitialPageIndex = () => {
    const storedValue = localStorage.getItem("allDealsPage");
    return storedValue ? parseInt(storedValue, 10) : 0;
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: influencerData,
      initialState: { pageIndex: getInitialPageIndex(), pageSize: 10 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (roles !== "Influencer") {
          return [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <Checkbox1 {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => (
                <Checkbox1 {...row.getToggleRowSelectedProps()} />
              ),
            },
            ...columns,
          ];
        }
        return columns;
      });
    }
  );

  const handleProspectClick = () => {
    setIsOpenProspectFormModal(true);
  };

  const handleDateChange = (date, name, row) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDate(date);
    setEditedRowData({ ...editedRowData, [name]: formattedDate });
    setInfluencerData((prevNewAddDeals) => {
      // Find the index of the row to update
      const index = prevNewAddDeals.findIndex((ro) => ro?.row_Id == row.id);

      // If the row is found, update it
      if (index !== -1) {
        // Create a new array with the updated row
        const updatedNewAddDeals = [...prevNewAddDeals];
        updatedNewAddDeals[index] = {
          ...updatedNewAddDeals[index],
          [name]: formattedDate,
        };

        // Return the updated array to update the state
        return updatedNewAddDeals;
      }

      // If the row is not found, return the previous state
      return prevNewAddDeals;
    });
  };

  useEffect(() => {
    if (editedRowData) {
      calculatePayouts();
      // setEditedRowData({
      //   ...editedRowData,
      //   manager_payout: editedRowData.deal_amount * (editedRowData.manager_split / 100),
      //   creator_payout: editedRowData.deal_amount *(1 - (editedRowData.manager_split + editedRowData.cm_split) / 100),
      //   cm_payout: editedRowData.deal_amount * (editedRowData.cm_split / 100),
      // });
    }
  }, [
    editedRowData?.deal_amount,
    editedRowData?.manager_split,
    editedRowData?.cm_split,
  ]);

  const calculatePayouts = () => {
    const dealAmount = parseFloat(editedRowData.deal_amount) || 0;
    const managerSplit = parseFloat(editedRowData.manager_split) || 0;
    const cmSplit = parseFloat(editedRowData.cm_split) || 0;

    const creatorPayout = dealAmount * (1 - (managerSplit + cmSplit) / 100);
    const managerPayout = dealAmount * (managerSplit / 100);
    const cmPayout = dealAmount * (cmSplit / 100);

    setEditedRowData((prevState) => ({
      ...prevState,
      creator_payout: `${creatorPayout.toFixed(2)}`,
      manager_payout: `${managerPayout.toFixed(2)}`,
      cm_payout: `${cmPayout.toFixed(2)}`,
    }));
  };

  useEffect(() => {
    const Path = window.location.pathname;
    const Parts = Path.split("/");
    const part = Parts[Parts.length - 1];

    setIdPart(part);
  }, []);

  useEffect(() => {
    if (IdPart) {
      fetchAllInfluencerDealsData(IdPart);
    }
  }, [IdPart]);

  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
      handleSaveRow();
      // setSelectedCompanies();
      // setInputCompanyValue([])
      // setSelectedCompanies([]);
      setSelectedDate(null);
    }
  };

  function parseFormattedDate(dateString) {
    // Parse the date string back to a Date object
    const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
    // Validate the parsed date
    return isValid(parsedDate) ? parsedDate : null;
  }

  function isValidISODate(dateString) {
    if (!dateString) {
      return false; // Handle null or undefined input
    }
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!isoDateRegex.test(dateString)) {
      return false; // If the date string doesn't match the ISO format, return false
    }
    const date = new Date(dateString);
    return !isNaN(date);
  }

  const handleCellClick = (row, cell) => {
    if (roles === "Influencer") {
      return;
    }

    if (
      cell.column.id == "creator" ||
      cell.column.id == "view_deals" ||
      cell.column.id == "follow_up" ||
      cell.column.id == "selection" ||
      cell.column.id == "creator_payout" ||
      cell.column.id == "manager_payout" ||
      cell.column.id == "cm_payout" ||
      cell.column.id == "manager_payout"
      // ||
      // cell.column.id == "contract"
    ) {
      return;
    } else {
      if (cell.column) {
        if (row.original.brand_agency !== "") {
          handleBrandRepo(row.original.brand_agency);
        }
        setIsEditing(row.original.deal_id);

        // Check if the selected cell contains a valid ISO date
        let isDate = isValidISODate(row.original[cell.column.id]);

        if (isDate) {
          const parsedDate = parseFormattedDate(row.original[cell.column.id]);
          if (parsedDate) {
            let formattedDate = format(parsedDate, "yyyy-MM-dd");
            setSelectedDate(formattedDate);
          }
        } else {
          setSelectedDate(null);
        }

        setInfluencerId(row.original.influencer);
        setDealsId(row.original.deal_id);
        setEditedRowData(row.values);
        setEditingCell(cell.column.id);

        // Store the previous edited row data
        previousRowDataRef.current = editedRowData;
      }
    }
  };

  const handleSaveNew = async (newAdder) => {
    try {
      const response = await fetch(BASE_URL + "/create-deals/", {
        method: "POST",
        body: JSON.stringify({ deals_data: [newAdder] }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        setShowButton(false);
        fetchAllInfluencerDealsData();
        // fetchAllInfluencerDealsData();
        // window.location.reload()
        toast({
          title: "Deal Created",
          description: "Deal created successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Deals created Failed",
          description: "Failed to created Deals.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to Created Deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleBrandRepo = async (companyName) => {
    try {
      const response = await fetch(BASE_URL + "/prospects-search/", {
        method: "POST",
        body: JSON.stringify({ company_name: companyName }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      setBrandRep(data.prospects);
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: "Failed to fetch prospect Deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleSaveRow = async () => {
    if (editedRowData) {
      if (!editedRowData?.deal_id) {
        editedRowData.deal_id = dealsId;
        editedRowData.influencer = influenserId;
      }
      try {
        const response = await fetch(BASE_URL + "/update-deals/", {
          method: "PUT",
          body: JSON.stringify({ deal_data: editedRowData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (response.ok) {
          setIsEditing(null);
          setEditedRowData(null);
          fetchAllInfluencerDealsData();
          toast({
            title: "Deal Updated",
            description: "Deal updated successfully.",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Deal Update Failed",
            description: "Failed to update deal details.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Deal Update Failed",
          description: "Failed to update deal details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error:", error);
      }
    }
  };

  const handleExit = () => {
    navigate(`/admin/deals?page=${backPage}`);
  };

  useEffect(() => {
    const page = localStorage.getItem("page");

    setBackPage(page);
  }, []);

  const handleCheckboxChange = (value) => {
    gotoPage(0);
    const currentIndex = selectedStage.indexOf(value);

    const newSelected = [...selectedStage];

    currentIndex === -1
      ? newSelected.push(value)
      : newSelected.splice(currentIndex, 1);
    setselectedStage(newSelected);
  };

  const handleMissingCheckboxChange = (value) => {
    gotoPage(0);
    const currentIndex = selectedMissing.indexOf(value);

    const MissingSelected = [...selectedMissing];

    currentIndex === -1
      ? MissingSelected.push(value)
      : MissingSelected.splice(currentIndex, 1);
    setselectedMissing(MissingSelected);
  };

  const handleCompanyClick = (company) => {
    setInputCompanyValue(company);
    gotoPage(0);
    // setInputCompanyValue('');
    if (!selectedCompanies?.includes(company)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const handleRowCompanyClick = (company, index, fieldName, row, cell) => {
    // setInputCompanyValue(company);

    setEditedRowData({
      ...editedRowData,
      [fieldName]: company,
      brand_rep: "",
      deal_id: row.original.deal_id,
      influencer: row?.original?.influencer,
    });

    setInfluencerData((prevNewAddDeals) => {
      if (index !== -1) {
        // Create a new array with the updated row
        const updatedNewAddDeals = [...prevNewAddDeals];
        updatedNewAddDeals[index] = {
          ...updatedNewAddDeals[index],
          [fieldName]: company,
          brand_rep: "",
        };
        // Return the updated array to update the state
        return updatedNewAddDeals;
      }

      // If the row is not found, return the previous state
      return prevNewAddDeals;
    });

    row.values.brand_agency = company;
    row.values.brand_rep = "";
    handleCellClick(row, cell);
    handleBrandRepo(company);
  };

  const handleRemoveCompany = (index) => {
    const updatedCompanyData = selectedCompanies.filter(
      (_, item) => item !== index
    );
    setSelectedCompanies(updatedCompanyData);
  };

  const handleStartDateChange = (e) => {
    // const formattedDate = format(e.target.value, "yyyy-MM-dd");
    SetSelectedStartDate(e.target.value);
    setStartDate(e.target.value);
    // Clear error if any
    if (error) setError(null);
  };

  const handleEndDateChange = (e) => {
    // const formattedDate = format(date, "yyyy-MM-dd");
    if (
      selectedStartdate &&
      e.target.value &&
      e.target.value <= selectedStartdate
    ) {
      setError("End date must be after the start date");
    } else {
      SetSelectedEndDate(e.target.value);
      setEndDate(e.target.value);
      // Clear error if any
      if (error) setError(null);
    }
  };

  const fetchAllInfluencerDealsData = async () => {
    try {
      const stageParams = selectedStage
        .map((stage) => `stage=${stage}`)
        .join("&");
      const createrParams = selectedCreator
        .map((creator) => `creator_manager=${creator}`)
        .join("&");
      const companyParams = selectedCompanies
        .map((company) => `brand_agency=${company}`)
        .join("&");
      const talentParams = selectedTalents
        .map((talent) => `creator=${talent}`)
        .join("&");
      const InvoiceParams = selectedInvoice
        .map((InvoiceNum) => `invoice_number=${InvoiceNum}`)
        .join("&");
      const missing_dataParams = selectedMissing
        .map((Missing_data) => `missing_data=${Missing_data}`)
        .join("&");
      const start_dateParams = `start_date=${startDate}`;
      const End_DateParams = `end_date=${endDate}`;

      const response = await fetch(
        BASE_URL +
          `/get-all-deals/?${stageParams}&${createrParams}&${
            start_dateParams != "start_date=" ? start_dateParams : ""
          }&${
            End_DateParams != "end_date=" ? End_DateParams : ""
          }&${companyParams}&${talentParams}&${InvoiceParams}&${missing_dataParams}&page=${
            pageIndex + 1
          }&page_size=${pageSize}&ordering=-created_ts`,
        {
          method: "GET", // You may need to specify the method explicitly as 'GET'
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          //   body : JSON.stringify({ influencer_id: Id || influenserId }),
        }
      );
      const data = await response.json();

      if (!data) {
        return;
      }
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }

      setInfluencerData(data?.Deals_data ?? []);
      setPageCount(data?.paginator?.total_pages ?? 0);
      setTotalItems(data?.paginator?.total_items ?? 0);
      setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);

      toast({
        title: "Fetch Error.",
        description: "Failed to fetch  details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllInfluencerDealsData();
  }, [
    pageIndex,
    pageSize,
    selectedStage,
    selectedCreator,
    selectedCompanies,
    selectedTalents,
    selectedInvoice,
    startDate,
    endDate,
    selectedMissing,
  ]);

  useEffect(() => {
    handleSaveRow();
    if (intCompanyName) {
      handleBrandRepo(intCompanyName);
    }
  }, [stateChange, intCompanyName]);

  const fetchCompanies = async () => {
    try {
      setIsCompanySearchLoading(true);
      const response = await fetch(
        `${BASE_URL}/companies-search/?company_search=${inputCompanyValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      setFilteredCompanyData(data?.companies);
      setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputCompanyValue?.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchCompanies();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputCompanyValue]);

  const fetchTalents = async () => {
    try {
      setIsTalentSearchLoading(true);
      const response = await fetch(
        // BASE_URL + `/get-all-deals/?creator=${inputTalentValue}`,
        BASE_URL + `/talent-search/?talent_name=${inputTalentValue}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      // const uniqueCreators = data?.Deals_data?.map((row) => row.creator).filter(
      //   (creator, index, self) => self.indexOf(creator) === index
      // );

      setFilteredTalentData(data?.talent_names);

      setIsTalentSearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchInvoiceNumber = async () => {
    try {
      setIsInvoiceSearchLoading(true);
      const response = await fetch(
        BASE_URL + `/get-all-deals/?invoice_number=${inputInvoiceValue}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      //     const uniqueCreators = data?.All_deals_data
      //     ?.map((row) => row.creator)
      //     .filter((creator, index, self) => self.indexOf(creator) === index);

      //
      const InvoiceNumbers = data.Deals_data.map((row) => {
        return row.invoices.map((ele) => ele.invoice_number);
      });

      // Combine all nested arrays into one
      const combinedArray = InvoiceNumbers.flat();
      const FinalInvoiceNumber = combinedArray.filter((number, index, self) => {
        return self.indexOf(number) === index;
      });

      setFilteredInvoiceData(FinalInvoiceNumber);
      setIsInvoiceSearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputTalentValue.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchTalents();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputTalentValue]);

  useEffect(() => {
    if (inputInvoiceValue.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchInvoiceNumber();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputInvoiceValue]);

  const resetAllFilter = () => {
    setselectedStage([]);
    setSelectedCompanies([]);
    setSelectedTalents([]);
    setSelectedInvoice([]);
  };

  const resetAllField = () => {
    setInputCompanyValue("");
    setInputInvoiceValue("");
    setInputTalentValue("");
  };

  const handleDeleteRows = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      const dealIds = selectedFlatRows.map((row) => row?.original?.deal_id);
      const response = await fetch(BASE_URL + "/delete-deals/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ deal_ids: dealIds }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (response.ok) {
        // setIsLoading(false);

        setInfluencerData((prevData) =>
          prevData.filter((deal) => !dealIds.includes(deal.deal_id))
        );
        fetchAllInfluencerDealsData();
        toast({
          title: "Deletion Success",
          description: "Deal deleted successfully",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenDealsDeleteDialogBox(false);
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete Deals details1.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenDealsDeleteDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete Deals details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setIsOpenDealsDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  // CSv

  const handleExportCSV = async () => {
    const InvoiceParams = selectedInvoice
      .map((InvoiceNum) => `invoice_number=${InvoiceNum}`)
      .join("&");
    const stageParams = selectedStage
      .map((stage) => `stage=${stage}`)
      .join("&");
    const createrParams = selectedCreator
      .map((creator) => `creator_manager=${creator}`)
      .join("&");
    const companyParams = selectedCompanies
      .map((company) => `brand_agency=${company}`)
      .join("&");
    const talentParams = selectedTalents
      .map((talent) => `creator_manager=${talent}`)
      .join("&");
    const missing_dataParams = selectedMissing
      .map((Missing_data) => `missing_data=${Missing_data}`)
      .join("&");
    const start_dateParams = `start_date=${startDate}`;
    const End_DateParams = `end_date=${endDate}`;

    try {
      setIsExportLoading(true);
      const response = await fetch(
        BASE_URL +
          `/get-all-deals-csv/?${stageParams}&${createrParams}&${
            start_dateParams != "start_date=" ? start_dateParams : ""
          }&${
            End_DateParams != "end_date=" ? End_DateParams : ""
          }&${companyParams}&${InvoiceParams}&${talentParams}&${missing_dataParams}&page=${
            pageIndex + 1
          }&page_size=${pageSize}&ordering=-updated_ts`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ influencer_id: influenserId }),
        }
      );
      const csvData = await response.text();

      try {
        const data = JSON.parse(csvData);
        if (data.code === "token_not_valid") {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }

      const headersMap = {
        deal_name: "Deal Name",
        date_received: "Date Received",
        influencer_talent_name: "Talent Name",
        stage: "Stage",
        creator: "Creator",
        products: "Products",
        usage: "Usage",
        deal_type: "Deal_Type",
        brand_agency: "Brand Agency",
        brand_rep: "Brand Rep",
        notes: "Notes",
        deal_amount: "Deal Amount",
        cm_split: "Cm Split",
        manager_split: "Manager Split",
        invoice_contact: "Invoice Contact",
        invoice: "Invoice",
        creator_payout: "Creator Payout",
        manager_payout: "Manager Payout",
        cm_payout: "Cm Payout",
        is_scheduled: "Is Scheduled",

        deliverables: "Deliverables",
        exclusivity: "Exclusivity",
        rough_draft_date: "Rough Draft Date",
        live_date: "Live Date",
        live_links: "Live Links",
        contract: "Contract",
        tracking_info: "Tracking Info",
        payment_term: "Payment Term",
        invoice_date: "Invoice Date",
        payout_date: "Payout Date",
        "creator_payout\r": "Creator Payout",
        "created_ts\r": "Created ts",
      };
      const rows = csvData.split("\n").map((row) => row.split(","));

      const headers = rows.shift(); // Extract headers

      const newHeaders = headers.map((header) => headersMap[header]); // Map original headers to new headers

      rows.unshift(newHeaders);
      // Convert date format
      rows.forEach((row) => {
        row[0] = combineDateTime(row[0]);
        row[7] = combineDateTime(row[7]);
        row[8] = combineDateTime(row[8]);
      });

      // Convert back to CSV format
      const modifiedCsvData = rows.map((row) => row.join(",")).join("\n");

      function combineDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        if (isNaN(date.getTime())) {
          return dateTimeString; // Return original string if invalid date
        }
        return date
          .toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      }

      setIsExportLoading(false);
      const blob = new Blob([modifiedCsvData], { type: "text/csv" });
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = String(currentDate.getFullYear()).slice(2); // Get last two digits of the year

      const dateFormatted = `${month}${day}${year}`;

      let csvName = "Deals Analytics - ";

      if (selectedStage.length > 0 || selectedCompanies.length > 0) {
        if (selectedStage.length > 0) {
          csvName += selectedStage.join("_");
        }
        if (selectedCompanies.length > 0) {
          csvName += " - " + selectedCompanies.join("_");
        }
        // if (selectedCompanies.length > 0) {
        //   csvName += ' - ' + selectedCompanies.join('_');
        // }
        csvName += ` - ${dateFormatted}.csv`;
      } else {
        csvName += ` ${dateFormatted}.csv`;
      }

      const blobUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = csvName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {}
  };

  const handleAddRow = (row) => {
    const Newrow = {
      influencer: influencerData[0]?.influencer,
      creator: influencerData[0]?.creator,
      brand_agency: "",
      brand_rep: "",
      cm_payout: null,
      cm_split: null,
      contract: "",
      creator_payout: "",
      date_received: null,
      deal_amount: null,
      deal_name: "",
      deal_type: "",
      exclusivity: "",

      invoice: "",
      invoice_contact: "",
      invoice_date: null,
      live_date: null,
      live_links: "",
      manager_payout: "",
      manager_split: null,
      notes: "",
      payment_term: "",
      payout_date: null,
      products: "",
      rough_draft_date: null,
      stage: "",
      tracking_info: "",
      usage: "",
      row_Id: influencerData.length,
      tracking_info: "",
    };
    setInfluencerData([Newrow, ...influencerData]);

    setShowButton(true);
    handleSaveNew(Newrow);
    setInfluencerRerender(!influencerRerender);
  };

  const handleDealFollowUp = (row) => {
    setRowData(row);
    setisOpenDealsFollowConfirmModel(true);
  };
  const handleState = (childData) => {
    SetIcon(childData);
  };

  const handleClock = async (row) => {
    setDeleteFollowId(row.original.deal_id);
    setIsOpenClockModel(true);

    const object = {
      deal_id: row.original.deal_id,
    };
    try {
      const result = await axios.post(`${BASE_URL}/follow-up/`, object, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClockDate(result.data.trigger);

      if (result.status === 200) {
        //  onClose();
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: "Error in sending mail.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const DeleteFolowUp = async () => {
    const object = {
      deal_id: DeleteFollowId,
    };
    try {
      const result = await axios.delete(`${BASE_URL}/follow-up/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: object,
      });

      if (result.status === 200) {
        fetchAllInfluencerDealsData();
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: "Error in sending mail.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInvoice = async (selectedFlatRows) => {
    setActiveDealsTab(true);

    setbrandForInvoice(selectedFlatRows[0]?.original?.brand_agency);
    setDealAmountForInvoice(selectedFlatRows[0]?.original?.deal_amount);
    setBrandRepForInvoice(selectedFlatRows[0]?.original?.brand_rep);
    setDeliverablsForInvoice(selectedFlatRows[0]?.original?.deliverables);
    setliveLinkForInvoice(selectedFlatRows[0]?.original?.live_links);
    setInvoiceForInvoice(selectedFlatRows[0]?.original?.invoice);
    setDealIdForInvoice(selectedFlatRows[0]?.original?.deal_id);
    setPaymentTermForInvoice(selectedFlatRows[0]?.original?.payment_term);
    setTalentName(selectedFlatRows[0]?.original?.creator);

    const object = {
      company_name: selectedFlatRows[0]?.original?.brand_agency,
      prospect_name: selectedFlatRows[0]?.original?.brand_rep,
    };
    try {
      const result = await axios.post(
        `${BASE_URL}/get_prospect_location/`,

        { ...object },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        fetchAllInfluencerDealsData();
        setLoctionForInvoice(result.data.location);
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: "Error in sending mail.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }

    const data = {
      brand_agency: selectedFlatRows[0]?.original?.brand_agency,
      brand_rep: selectedFlatRows[0]?.original?.brand_rep,
    };
    try {
      const result = await axios.post(`${BASE_URL}/prospect-email/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        setMailForInvoice(result.data.email);

        //  onClose();
      }
    } catch (error) {}
    setPrintButton(true);
    setisOpenInvoice(true);
  };

  const Dateprops = (dte) => {
    setInvoiceDate(dte);
  };

  useEffect(() => {
    Dateprops();
  }, []);

  const handleFileChange = (e, row, index) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file, row);
      handleFileUpload(file, row);
      // toast({
      //   title: "File selected",
      //   description: `${file.name} (${formatFileSize(file.size)})`,
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });
      // Reset the file input value
      //fileInputRef.current.value = null;
    }
  };
  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 10024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + " " + sizes[i];
  };
  const backgroundColor =
    isDragging || isLoading
      ? isLoading
        ? "#f5f5dc"
        : "#e0e7ff"
      : "transparent";

  const handleFileUpload = async (file, row) => {
    const Deal_ids = row.original.deal_id;
    if (!file) return;

    // setIsLoading(true);
    const formData = new FormData();
    formData.append("contract_file", file);

    try {
      const response = await fetch(`${BASE_URL}/deal-contract/${Deal_ids}/`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // If you need to pass a token
        },
      });
      const data = await response.json();

      if (data) {
        //    setReview(data.review.response)
        toast({
          title: "File uploaded",
          description: "Your file has been uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Upload failed",
          description: "There was an error uploading your file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Upload faileds",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
      fetchAllInfluencerDealsData();
      //   window.location.reload()
    }
  };

  const handleTalentClick = (talent) => {
    gotoPage(0);
    if (!selectedTalents?.includes(talent)) {
      setSelectedTalents([...selectedTalents, talent]);
    }
  };

  const handleRemoveTalent = (index) => {
    const updatedTalentData = selectedTalents.filter(
      (_, item) => item !== index
    );
    setSelectedTalents(updatedTalentData);
  };

  const handleInvoiceClick = (InvoiceNumber) => {
    gotoPage(0);
    if (!selectedInvoice?.includes(InvoiceNumber)) {
      setSelectedInvoice([...selectedInvoice, InvoiceNumber]);
    }
  };

  const handleRemoveInvoice = (index) => {
    const updatedInvoiceData = selectedInvoice.filter(
      (_, item) => item !== index
    );
    setSelectedInvoice(updatedInvoiceData);
  };

  const fetchCreator = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/influencers/?ordering=-created_ts`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Process the response
      if (response.status === 200) {
        const AllCreators = response.data.influencers_data.map((row) => {
          return {
            talentName: row.talent_name,
            influencerId: row.influencer_id, // Replace with actual property name
          };
        });
        // setAllCreatorsName(AllCreators)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchCreator();
  }, []);

  useEffect(() => {
    // Example: Setting a query parameter in the URL
    if (roles !== "Influencer") {
      const queryParams = new URLSearchParams(window.location.search);

      queryParams.set("AllPages", pageIndex + 1);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

      // Modify the current history entry
      window.history.replaceState(null, "", newUrl);
    }
  }, [pageIndex]);

  useEffect(() => {
    localStorage.setItem("allDealsPage", pageIndex);
  }, [pageIndex]);

  const handleContractRemove = async (row) => {
    setIsLoading(true); // Set loading state to true at the start

    const dealId = row[0]?.original?.deal_id;

    try {
      const response = await fetch(`${BASE_URL}/deal-contract/${dealId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();

        toast({
          title: "Deletion Success",
          description: "Contract file has been deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Deletion Failed",
          description: "There was an error deleting your contract.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "There was an error deleting the contract.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error deleting contract:", error);
    } finally {
      setIsLoading(false); // Set loading state to false at the end
      fetchAllInfluencerDealsData(); // Fetch updated deals data
    }
  };

  const genrateCreator = async () => {
    try {
      // Ensure that you await the result of fetchInfluencerMetaData
      const AllCreatorInfluencer = await fetchInfluencerMetaData(token);

      const AllCreators = AllCreatorInfluencer?.map((row) => {
        return {
          talentName: row.name,
          influencerId: row.id,
        };
      });

      setAllCreatorsName(AllCreators);
    } catch (error) {
      //
      console.error("Error fetching creator influencer data:", error);
    }
  };

  useEffect(() => {
    genrateCreator();
  }, []);

  return (
    <>
      <ChakraProvider />
      <Box px={5} py={3} className="alldeal_tab_padding">
        <SkeletonText
          noOfLines={20}
          spacing="2"
          skeletonHeight="6"
          isLoaded={!isLoading}
        >
          {selectedFlatRows.length > 0 ? (
            <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
              <Box>{selectedFlatRows.length} selected</Box>
              <Flex alignItems={"center"} gap={3}>
                {isEditing && (
                  <Button
                    onClick={handleSaveRow}
                    size={"sm"}
                    colorScheme="blue"
                  >
                    {" "}
                    Save
                  </Button>
                )}
                {!isEditing &&
                  selectedFlatRows.length === 1 &&
                  selectedFlatRows[0]?.original?.stage ===
                    "Offer with Talent" && (
                    <Button
                      onClick={() => setIsOpenInfluencerMailModel(true)}
                      size={"sm"}
                      colorScheme="red"
                      variant="solid"
                    >
                      <RiMailSendFill />
                    </Button>
                  )}
                {!isEditing &&
                  selectedFlatRows.length === 1 &&
                  selectedFlatRows[0]?.original?.stage === "Invoiced" &&
                  roles !== "Influencer" && (
                    <Button
                      size={"sm"}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => handleInvoice(selectedFlatRows)}
                    >
                      <FaFileInvoiceDollar />
                    </Button>
                  )}
                {!isEditing &&
                  selectedFlatRows[0]?.original.contract !== null &&
                  roles !== "Influencer" &&
                  selectedFlatRows.length === 1 && (
                    <Button
                      size={"sm"}
                      colorScheme="red"
                      variant="solid"
                      onClick={() => handleContractRemove(selectedFlatRows)}
                    >
                      <FaFileCircleXmark />
                    </Button>
                  )}
                {!isEditing && roles !== "Influencer" && (
                  <Button
                    onClick={() => setIsOpenDealsDeleteDialogBox(true)}
                    size={"sm"}
                    colorScheme="red"
                    variant="solid"
                  >
                    <IoMdTrash size={20} color="white" />
                  </Button>
                )}
              </Flex>
            </Flex>
          ) : (
            <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
              <Box>
                <Flex gap={4}>
                  <Button
                    mb={3}
                    // onClick={() => handleExit()}
                    // size={"sm"}
                    // colorScheme="custom.black"
                    // variant="solid"
                    bg="custom.black"
                    color="custom.white"
                    _hover={{ bg: "custom.black", color: "custom.white" }}
                  >
                    {/* <IoIosArrowBack size={20} /> */}
                  </Button>
                </Flex>
              </Box>

              <Box>
                <Box>
                  <Flex gap={4}>
                    {roles !== "Influencer" && (
                      <>
                        <Button
                          onClick={() => handleAddRow()}
                          size={"sm"}
                          colorScheme="yellow"
                          variant="solid"
                        >
                          <TbPencilDollar size={20} />
                        </Button>
                        <Button
                          onClick={() => setIsOpenCompanyFormModal(true)}
                          size={"sm"}
                          colorScheme="blue"
                          variant="solid"
                        >
                          <BsBuildingFillAdd size={20} color="white" />
                        </Button>
                        <Button
                          mb={3}
                          onClick={handleProspectClick}
                          size={"sm"}
                          colorScheme="blue"
                          variant="solid"
                        >
                          <AiOutlineUsergroupAdd size={20} color="white" />
                        </Button>
                      </>
                    )}
                    {showButton ? <Flex gap={2}></Flex> : ""}
                    <Button
                      isLoading={isExportLoading}
                      spinner={<PulseLoader size={8} color="purple" />}
                      spinnerPlacement="end"
                      loadingText="Exporting"
                      onClick={handleExportCSV}
                      size={"sm"}
                      colorScheme="purple"
                      variant="outline"
                    >
                      <BsFiletypeCsv size={20} />
                    </Button>
                    <Box>
                      <Menu closeOnSelect={false}>
                        <MenuButton
                          height={"2rem"}
                          minWidth={"2.3rem"}
                          as={IconButton}
                          aria-label="Options"
                          icon={<BsFilter size={18} />}
                          variant="outline"
                        />
                        <MenuList maxHeight="80vh" overflowY="auto">
                          <Flex flexDirection={"column"}>
                            <Flex
                              className="flex-none "
                              justifyContent={"space-between"}
                            >
                              <MenuGroup>
                                <Flex
                                  w={"320px"}
                                  px={3}
                                  pb={2}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Text fontSize={"sm"} fontWeight={600}>
                                    Stage
                                  </Text>
                                  {selectedStage.length > 0 && (
                                    <Tag
                                      borderRadius="full"
                                      size={"sm"}
                                      colorScheme="red"
                                      variant="outline"
                                      cursor={"pointer"}
                                      onClick={() => setselectedStage([])}
                                    >
                                      {selectedStage.length > 0 &&
                                        `Clear (${selectedStage.length})`}
                                    </Tag>
                                  )}
                                </Flex>
                                <Flex px={3} gap={5}>
                                  <Wrap
                                    maxH="115px"
                                    overflowY="auto"
                                    pr="2"
                                    sx={{
                                      "&::-webkit-scrollbar": { width: "2px" },
                                      "&::-webkit-scrllbar-track": {
                                        background: "#f1f1f1",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        background: "#888",
                                      },
                                      "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#555",
                                      },
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <Flex
                                      w={"320px"}
                                      flexDirection={"column"}
                                      gap={1}
                                    >
                                      <Checkbox
                                        value="New Lead"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("New Lead")
                                        }
                                        isChecked={selectedStage.includes(
                                          "New Lead"
                                        )}
                                      >
                                        New Lead
                                      </Checkbox>
                                      <Checkbox
                                        value="Rates Sent"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Rates Sent")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Rates Sent"
                                        )}
                                      >
                                        Rates Sent
                                      </Checkbox>

                                      <Checkbox
                                        value="Offer with Talent"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "Offer with Talent"
                                          )
                                        }
                                        isChecked={selectedStage.includes(
                                          "Offer with Talent"
                                        )}
                                      >
                                        Offer with Talent
                                      </Checkbox>

                                      <Checkbox
                                        value="Verbal Approval"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "Verbal Approval"
                                          )
                                        }
                                        isChecked={selectedStage.includes(
                                          "Verbal Approval"
                                        )}
                                      >
                                        Verbal Approval
                                      </Checkbox>
                                      <Checkbox
                                        value="Contract Pending"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "Contract Pending"
                                          )
                                        }
                                        isChecked={selectedStage.includes(
                                          "Contract Pending"
                                        )}
                                      >
                                        Contract Pending
                                      </Checkbox>

                                      <Checkbox
                                        value="Production"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Production")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Production"
                                        )}
                                      >
                                        Production
                                      </Checkbox>
                                      <Checkbox
                                        value="Live"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Live")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Live"
                                        )}
                                      >
                                        Live
                                      </Checkbox>

                                      <Checkbox
                                        value="Invoiced"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Invoiced")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Invoiced"
                                        )}
                                      >
                                        Invoiced
                                      </Checkbox>

                                      <Checkbox
                                        value="Collected"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Collected")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Collected"
                                        )}
                                      >
                                        Collected
                                      </Checkbox>

                                      {/*                                                   
                                                    <Flex flexDirection={"column"} gap={1}>  */}

                                      <Checkbox
                                        value="Closed Won"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Closed Won")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Closed Won"
                                        )}
                                      >
                                        Closed Won
                                      </Checkbox>

                                      <Checkbox
                                        value="Closed Lost"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Closed Lost")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Closed Lost"
                                        )}
                                      >
                                        Closed Lost
                                      </Checkbox>

                                      <Checkbox
                                        value="Gifting Only"
                                        colorScheme="green"
                                        onChange={() =>
                                          handleCheckboxChange("Gifting Only")
                                        }
                                        isChecked={selectedStage.includes(
                                          "Gifting Only"
                                        )}
                                      >
                                        Gifting Only
                                      </Checkbox>
                                    </Flex>
                                  </Wrap>
                                </Flex>
                                {/* <Flex px={3} gap={2} mt={3}>
                        <Flex flexDirection={"column"} gap={1}>
                        <FormLabel fontSize={"sm"} fontWeight={600}>Missing Data</FormLabel>
                          <Checkbox
                            value="Missing Data"
                            colorScheme="green"
                            onChange={() =>
                              handleMissingCheckboxChange("Missing Data")
                            }
                            isChecked={selectedMissing.includes("Missing Data")}
                          >
                            Missing Data
                          </Checkbox>
                        </Flex>
                      </Flex> */}
                                <Flex px={3} gap={2} mt={3}>
                                  <Flex flexDirection={"column"} gap={1}>
                                    <Flex
                                      w={"320px"}
                                      pb={2}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Text fontSize={"sm"} fontWeight={600}>
                                        Brand
                                      </Text>
                                      {selectedCompanies.length > 0 && (
                                        <Tag
                                          borderRadius="full"
                                          size={"sm"}
                                          colorScheme="red"
                                          variant="outline"
                                          cursor={"pointer"}
                                          onClick={() =>
                                            setSelectedCompanies([])
                                          }
                                        >
                                          {selectedCompanies.length > 0 &&
                                            `Clear (${selectedCompanies.length})`}
                                        </Tag>
                                      )}
                                    </Flex>
                                    <Box px={3}>
                                      <InputGroup>
                                        <Input
                                          w={"300px"}
                                          mt={1}
                                          value={inputCompanyValue}
                                          onChange={(e) => {
                                            setInputCompanyValue(
                                              e.target.value
                                            );
                                            setIsCompanySearchLoading(true);
                                          }}
                                          placeholder="Search for companies..."
                                        />

                                        {inputCompanyValue ? (
                                          <InputRightElement>
                                            {isCompanySearchLoading ? (
                                              <Spinner
                                                w={4}
                                                h={4}
                                                color="gray.400"
                                              />
                                            ) : (
                                              <CloseIcon
                                                cursor={"pointer"}
                                                w={2.5}
                                                h={2.5}
                                                color="gray.400"
                                                onClick={() =>
                                                  setInputCompanyValue("")
                                                }
                                              />
                                            )}
                                          </InputRightElement>
                                        ) : (
                                          <InputRightElement>
                                            <SearchIcon
                                              w={3}
                                              h={3}
                                              color="gray.400"
                                            />
                                          </InputRightElement>
                                        )}
                                      </InputGroup>

                                      {inputCompanyValue && (
                                        <Card mt={1}>
                                          {filteredCompanyData?.length > 0 && (
                                            <Box
                                              my={1}
                                              maxH={"145px"}
                                              overflowY={"auto"}
                                              sx={{
                                                "&::-webkit-scrollbar": {
                                                  width: "4px",
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                  background: "#dddddd",
                                                  borderRadius: "100vh",
                                                },
                                              }}
                                            >
                                              {filteredCompanyData?.map(
                                                (company, index) => (
                                                  <Text
                                                    px={3}
                                                    py={1}
                                                    fontSize={"sm"}
                                                    _hover={{
                                                      bg: "#EDF2F7",
                                                      cursor: "pointer",
                                                    }}
                                                    key={index}
                                                    onClick={() =>
                                                      handleCompanyClick(
                                                        company
                                                      )
                                                    }
                                                  >
                                                    {company}
                                                  </Text>
                                                )
                                              )}
                                            </Box>
                                          )}
                                        </Card>
                                      )}

                                      <Box
                                        mt={1}
                                        maxW={"300px"}
                                        maxH={"55px"}
                                        overflow={"auto"}
                                        sx={{
                                          "&::-webkit-scrollbar": {
                                            width: "3px",
                                          },
                                          "&::-webkit-scrollbar-track": {
                                            background: "#f1f1f1",
                                            borderRadius: "10px",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            background: "#888",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        {selectedCompanies?.map(
                                          (value, index) => (
                                            <Tag
                                              mr={1}
                                              mb={1}
                                              key={index}
                                              size="md"
                                              variant="subtle"
                                              colorScheme="blackAlpha"
                                            >
                                              <TagLabel>{value}</TagLabel>
                                              <TagCloseButton
                                                onClick={() =>
                                                  handleRemoveCompany(index)
                                                }
                                              />
                                            </Tag>
                                          )
                                        )}
                                      </Box>
                                    </Box>
                                    <Box px={3}>
                                      <MenuGroup>
                                        <Flex flexDirection={"column"}>
                                          <Flex
                                            w={"320px"}
                                            px={3}
                                            pb={2}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                          >
                                            <Text
                                              fontSize={"sm"}
                                              fontWeight={600}
                                            >
                                              Talent
                                            </Text>
                                            {selectedTalents.length > 0 && (
                                              <Tag
                                                borderRadius="full"
                                                size={"sm"}
                                                colorScheme="red"
                                                variant="outline"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                  setSelectedTalents([])
                                                }
                                              >
                                                {selectedTalents.length > 0 &&
                                                  `Clear (${selectedTalents.length})`}
                                              </Tag>
                                            )}
                                          </Flex>
                                          <Box px={3}>
                                            <InputGroup>
                                              <Input
                                                w={"300px"}
                                                value={inputTalentValue}
                                                onChange={(e) => {
                                                  setInputTalentValue(
                                                    e.target.value
                                                  );
                                                  setIsTalentSearchLoading(
                                                    true
                                                  );
                                                }}
                                                placeholder="Search for talents..."
                                              />
                                              {/* {
                                                   
                                                    } */}
                                              {inputTalentValue ? (
                                                <InputRightElement>
                                                  {isTalentSearchLoading ? (
                                                    <Spinner
                                                      w={4}
                                                      h={4}
                                                      color="gray.400"
                                                    />
                                                  ) : (
                                                    <CloseIcon
                                                      cursor={"pointer"}
                                                      w={2.5}
                                                      h={2.5}
                                                      color="gray.400"
                                                      onClick={() =>
                                                        setInputTalentValue("")
                                                      }
                                                    />
                                                  )}
                                                </InputRightElement>
                                              ) : (
                                                <InputRightElement>
                                                  <SearchIcon
                                                    w={3}
                                                    h={3}
                                                    color="gray.400"
                                                  />
                                                </InputRightElement>
                                              )}
                                            </InputGroup>

                                            {inputTalentValue && (
                                              <Card mt={1}>
                                                {filteredTalentData?.length >
                                                  0 && (
                                                  <Box
                                                    my={1}
                                                    maxH={"145px"}
                                                    overflowY={"auto"}
                                                    sx={{
                                                      "&::-webkit-scrollbar": {
                                                        width: "4px",
                                                      },
                                                      "&::-webkit-scrollbar-thumb":
                                                        {
                                                          background: "#dddddd",
                                                          borderRadius: "100vh",
                                                        },
                                                    }}
                                                  >
                                                    {filteredTalentData?.map(
                                                      (talent, index) => (
                                                        <Text
                                                          px={3}
                                                          py={1}
                                                          fontSize={"sm"}
                                                          _hover={{
                                                            bg: "#EDF2F7",
                                                            cursor: "pointer",
                                                          }}
                                                          key={index}
                                                          onClick={() =>
                                                            handleTalentClick(
                                                              talent
                                                            )
                                                          }
                                                        >
                                                          {talent}
                                                        </Text>
                                                      )
                                                    )}
                                                  </Box>
                                                )}
                                              </Card>
                                            )}

                                            <Box
                                              mt={1}
                                              maxW={"300px"}
                                              maxH={"55px"}
                                              overflow={"auto"}
                                              sx={{
                                                "&::-webkit-scrollbar": {
                                                  width: "3px",
                                                },
                                                "&::-webkit-scrollbar-track": {
                                                  background: "#f1f1f1",
                                                  borderRadius: "10px",
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                  background: "#888",
                                                  borderRadius: "10px",
                                                },
                                              }}
                                            >
                                              {selectedTalents?.map(
                                                (value, index) => (
                                                  <Tag
                                                    mr={1}
                                                    mb={1}
                                                    key={index}
                                                    size="md"
                                                    variant="subtle"
                                                    colorScheme="blackAlpha"
                                                  >
                                                    <TagLabel>{value}</TagLabel>
                                                    <TagCloseButton
                                                      onClick={() =>
                                                        handleRemoveTalent(
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </Tag>
                                                )
                                              )}
                                            </Box>
                                          </Box>
                                        </Flex>
                                      </MenuGroup>
                                    </Box>

                                    <MenuGroup>
                                      <Flex flexDirection={"column"}>
                                        <Flex
                                          w={"320px"}
                                          px={3}
                                          pb={2}
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <Text
                                            fontSize={"sm"}
                                            fontWeight={600}
                                          >
                                            Invoice
                                          </Text>
                                          {selectedInvoice.length > 0 && (
                                            <Tag
                                              borderRadius="full"
                                              size={"sm"}
                                              colorScheme="red"
                                              variant="outline"
                                              cursor={"pointer"}
                                              onClick={() =>
                                                setSelectedInvoice([])
                                              }
                                            >
                                              {selectedInvoice.length > 0 &&
                                                `Clear (${selectedInvoice.length})`}
                                            </Tag>
                                          )}
                                        </Flex>
                                        <Box px={3}>
                                          <InputGroup>
                                            <Input
                                              w={"300px"}
                                              value={inputInvoiceValue}
                                              onChange={(e) => {
                                                setInputInvoiceValue(
                                                  e.target.value
                                                );
                                                setIsInvoiceSearchLoading(true);
                                              }}
                                              placeholder="Search for Invoice Number..."
                                            />
                                            {/* {

                                                    } */}
                                            {inputInvoiceValue ? (
                                              <InputRightElement>
                                                {isInvoiceSearchLoading ? (
                                                  <Spinner
                                                    w={4}
                                                    h={4}
                                                    color="gray.400"
                                                  />
                                                ) : (
                                                  <CloseIcon
                                                    cursor={"pointer"}
                                                    w={2.5}
                                                    h={2.5}
                                                    color="gray.400"
                                                    onClick={() =>
                                                      setInputInvoiceValue("")
                                                    }
                                                  />
                                                )}
                                              </InputRightElement>
                                            ) : (
                                              <InputRightElement>
                                                <SearchIcon
                                                  w={3}
                                                  h={3}
                                                  color="gray.400"
                                                />
                                              </InputRightElement>
                                            )}
                                          </InputGroup>

                                          {inputInvoiceValue && (
                                            <Card mt={1}>
                                              {filteredInvoiceData?.length >
                                                0 && (
                                                <Box
                                                  my={1}
                                                  maxH={"145px"}
                                                  overflowY={"auto"}
                                                  sx={{
                                                    "&::-webkit-scrollbar": {
                                                      width: "4px",
                                                    },
                                                    "&::-webkit-scrollbar-thumb":
                                                      {
                                                        background: "#dddddd",
                                                        borderRadius: "100vh",
                                                      },
                                                  }}
                                                >
                                                  {filteredInvoiceData?.map(
                                                    (InvoiceNumber, index) => (
                                                      <Text
                                                        px={3}
                                                        py={1}
                                                        fontSize={"sm"}
                                                        _hover={{
                                                          bg: "#EDF2F7",
                                                          cursor: "pointer",
                                                        }}
                                                        key={index}
                                                        onClick={() =>
                                                          handleInvoiceClick(
                                                            InvoiceNumber
                                                          )
                                                        }
                                                      >
                                                        {InvoiceNumber}
                                                      </Text>
                                                    )
                                                  )}
                                                </Box>
                                              )}
                                            </Card>
                                          )}

                                          <Box
                                            mt={1}
                                            maxW={"300px"}
                                            maxH={"55px"}
                                            overflow={"auto"}
                                            sx={{
                                              "&::-webkit-scrollbar": {
                                                width: "3px",
                                              },
                                              "&::-webkit-scrollbar-track": {
                                                background: "#f1f1f1",
                                                borderRadius: "10px",
                                              },
                                              "&::-webkit-scrollbar-thumb": {
                                                background: "#888",
                                                borderRadius: "10px",
                                              },
                                            }}
                                          >
                                            {selectedInvoice?.map(
                                              (value, index) => (
                                                <Tag
                                                  mr={1}
                                                  mb={1}
                                                  key={index}
                                                  size="md"
                                                  variant="subtle"
                                                  colorScheme="blackAlpha"
                                                >
                                                  <TagLabel>{value}</TagLabel>
                                                  <TagCloseButton
                                                    onClick={() =>
                                                      handleRemoveInvoice(index)
                                                    }
                                                  />
                                                </Tag>
                                              )
                                            )}
                                          </Box>
                                        </Box>
                                      </Flex>
                                    </MenuGroup>
                                  </Flex>
                                </Flex>
                                <Flex px={3} gap={2} mt={3}>
                                  <Flex gap={1}>
                                    <Flex
                                      w={"320px"}
                                      pb={2}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <FormLabel
                                        fontSize={"sm"}
                                        fontWeight={600}
                                      >
                                        Start Date
                                      </FormLabel>
                                      <Input
                                        type="date"
                                        name="Start Date"
                                        value={selectedStartdate}
                                        onChange={(e) =>
                                          handleStartDateChange(e)
                                        }
                                        mt={1}
                                      />
                                    </Flex>
                                  </Flex>
                                </Flex>
                                <Flex px={3} gap={2} mt={3}>
                                  <Box>
                                    <Flex gap={1}>
                                      <Flex
                                        w={"320px"}
                                        pb={2}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                      >
                                        <FormLabel
                                          fontSize={"sm"}
                                          fontWeight={600}
                                        >
                                          End Date
                                        </FormLabel>
                                        <Input
                                          type="date"
                                          name="End  Date"
                                          value={selectedEnddate}
                                          onChange={(e) =>
                                            handleEndDateChange(e)
                                          }
                                        />
                                      </Flex>
                                    </Flex>
                                  </Box>
                                </Flex>
                                <Flex>
                                  {error && (
                                    <p style={{ color: "red" }}>{error}</p>
                                  )}
                                </Flex>
                              </MenuGroup>
                            </Flex>
                            {(selectedStage.length > 0 ||
                              selectedCreator.length > 0 ||
                              selectedCompanies.length > 0 ||
                              selectedTalents.length > 0 ||
                              selectedInvoice.length > 0) && (
                              <Flex
                                px={3}
                                pt={2}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Flex gap={5}>
                                  <Button
                                    size="sm"
                                    colorScheme="red"
                                    variant="link"
                                    onClick={resetAllFilter}
                                  >{`Reset All (${
                                    selectedStage.length +
                                    selectedCompanies.length +
                                    selectedCreator.length +
                                    selectedTalents.length +
                                    selectedInvoice.length
                                  })`}</Button>
                                  {(inputCompanyValue ||
                                    inputCreatorValue ||
                                    inputTalentValue ||
                                    inputInvoiceValue) && (
                                    <Button
                                      size="xs"
                                      colorScheme="red"
                                      variant="outline"
                                      onClick={resetAllField}
                                    >
                                      Clear All
                                    </Button>
                                  )}
                                </Flex>
                                <Text fontSize="sm" as={"b"} color="green">
                                  {totalItems} results found
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          )}
          <Box
            className="mobile_height"
            fontSize={14}
            mb={1}
            boxShadow="xs"
            h={"calc(92vh - 180px)"}
            // w={"calc(100vw - 290px)"}
            rounded={"md"}
            sx={{
              position: "relative",
            }}
          >
            <TableContainer
              {...getTableProps()}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: "scroll",
                "&::-webkit-scrollbar": { height: "10px", width: "0px" },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#3182ce",
                  borderRadius: "10px",
                },
              }}
            >
              <Table variant="simple" width={"100%"}>
                <Thead bg={"green.400"} className="fix-header">
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                          <Flex alignItems={"center"}>
                            {column.render("Header")}
                            <Box>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <HiArrowDown />
                                ) : (
                                  <HiArrowUp />
                                )
                              ) : (
                                ""
                              )}
                            </Box>
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);

                    const isRowEditing = isEditing === row?.original?.deal_id;

                    return (
                      <Tr
                        {...row.getRowProps()}
                        _hover={{
                          bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        {row.cells.map((cell, ind) => {
                          const isCellEditing =
                            (isRowEditing && editingCell === cell.column.id) ||
                            cell.column.id == "stage" ||
                            cell.column.id == "deal_type" ||
                            cell.column.id == "contract" ||
                            cell.column.id == "creator";

                          return (
                            <Td
                              {...cell.getCellProps()}
                              onTouchEnd={(e) => {
                                // Check if the touch event is a double-tap
                                if (e.timeStamp - lastTapTime < 600) {
                                  handleCellClick(row, cell);
                                }
                                setLastTapTime(e.timeStamp);
                              }}
                              onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                            >
                              {cell.column.id === "selection" ? (
                                <Checkbox1
                                  {...row.getToggleRowSelectedProps()}
                                  // disabled={isRowEditing}
                                />
                              ) : (row.original.stage === "Collected" ||
                                  row.original.stage === "Closed Won" ||
                                  row.original.stage === "Invoiced") &&
                                cell.column.id === "follow_up" ? (
                                <>
                                  {!row.original.is_scheduled ? (
                                    <Button
                                      size={"sm"}
                                      colorScheme="green"
                                      onClick={() => handleDealFollowUp(row)}
                                    >
                                      Set
                                    </Button>
                                  ) : (
                                    <Button onClick={() => handleClock(row)}>
                                      {" "}
                                      <FaRegClock />
                                    </Button>
                                  )}
                                </>
                              ) : cell.column.id === "live date" ||
                                cell.column.date_received ? (
                                isCellEditing ? (
                                  <Input
                                    type="date"
                                    value={selectedDate || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      row.values[cell.column.id] = value;
                                      setSelectedDate(value);
                                      setEditedRowData({
                                        ...editedRowData,
                                        [cell.column.id]: value,
                                        deal_id: row.original.deal_id,
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    _focus={{ zIndex: "0" }}
                                  />
                                ) : (
                                  format(
                                    new Date(row.original.created_ts),
                                    "MM/dd/yyyy"
                                  )
                                )
                              ) : isCellEditing ? (
                                cell.column.id === "stage" ? (
                                  <Select
                                    name="stage"
                                    className="custom-dropdown"
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                      setStateChange(!stateChange);
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    icon={<MdArrowDropDown />}
                                    placeholder="Select a Stage"
                                    _focus={{ zIndex: "0" }}
                                    isDisabled={roles === "Influencer"}
                                  >
                                    <option value="New Lead">New Lead</option>
                                    <option value="Rates Sent">
                                      Rates Sent
                                    </option>
                                    <option value="Offer with Talent">
                                      Offer with Talent
                                    </option>
                                    <option value="Verbal Approval">
                                      Verbal Approval
                                    </option>
                                    <option value="Contract Pending">
                                      Contract Pending
                                    </option>
                                    <option value="Production">
                                      Production
                                    </option>
                                    <option value="Live">Live</option>
                                    <option value="Invoiced">Invoiced</option>
                                    <option value="Collected">Collected</option>
                                    <option value="Closed Won">
                                      Closed Won
                                    </option>
                                    <option value="Closed Lost">
                                      Closed Lost
                                    </option>
                                    <option value="Gifting Only">
                                      Gifting Only
                                    </option>
                                  </Select>
                                ) : cell.column.id === "deal_type" ? (
                                  <Select
                                    name="deal type"
                                    className="custom-dropdown"
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                      setStateChange(!stateChange);
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    icon={<MdArrowDropDown />}
                                    placeholder="Select a Deal"
                                    _focus={{ zIndex: "0" }}
                                    isDisabled={roles === "Influencer"}
                                  >
                                    <option value="Inbox lead">
                                      Inbox lead
                                    </option>
                                    <option value="Outreach">Outreach</option>
                                    <option value="Upsell">Upsell</option>
                                    <option value="Usage only">
                                      Usage only
                                    </option>
                                  </Select>
                                ) : cell.column.id === "creator" ? (
                                  <Select
                                    name="creator"
                                    className="custom-dropdown"
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const selectedCreator =
                                        AllCreatorsName.find((creator) => {
                                          return (
                                            creator.talentName ===
                                            e.target.value
                                          );
                                        });

                                      if (selectedCreator) {
                                        const fieldName = cell.column.id;
                                        const value = e.target.value;

                                        row.values[fieldName] = value;
                                        setEditedRowData({
                                          ...editedRowData,
                                          [fieldName]: value,
                                          deal_id: row.original.deal_id,
                                          influencer:
                                            selectedCreator.influencerId,
                                        });
                                        setStateChange(!stateChange);
                                      }
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    icon={<MdArrowDropDown />}
                                    placeholder="Select a creator"
                                    _focus={{ zIndex: "0" }}
                                    isDisabled={roles === "Influencer"}
                                  >
                                    {/* <option value="" disabled>Select a creator</option> */}
                                    {AllCreatorsName &&
                                      AllCreatorsName.map((name, index) => (
                                        <option
                                          key={index}
                                          value={name.talentName}
                                        >
                                          {name.talentName}
                                        </option>
                                      ))}
                                  </Select>
                                ) : cell.column.id === "brand_rep" ? (
                                  <Select
                                    name="brand rep"
                                    className="custom-dropdown"
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                      setStateChange(!stateChange);
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    icon={<MdArrowDropDown />}
                                    placeholder="Select a Rep"
                                    _focus={{ zIndex: "0" }}
                                  >
                                    {brandRep?.map((opt) => {
                                      return (
                                        <option value={opt.full_name}>
                                          {opt.full_name}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                ) : cell.column.id === "contract" ? (
                                  <>
                                    {row && row?.original?.contract === null ? (
                                      roles !== "Influencer" && (
                                        <div className="upload_btn">
                                          <FaUpload size={12} />
                                          <Input
                                            type="file"
                                            onChange={(e) =>
                                              handleFileChange(e, row, index)
                                            }
                                            style={{ cursor: "pointer" }}
                                            accept=".pdf,.doc,.docx"
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <Button Size={"sm"} colorScheme="blue">
                                        <FaFileContract size={12} />
                                        <a
                                          href={`${BASE_URL}${row?.original?.contract}`}
                                          target="_blank"
                                        >
                                          FEA
                                        </a>
                                      </Button>
                                    )}
                                  </>
                                ) : cell.column.id === "deal_amount" ? (
                                  <Textarea
                                    name=""
                                    className=""
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    placeholder=""
                                    _focus={{ zIndex: "0" }}
                                  ></Textarea>
                                ) : cell.column.id === "cm_payout" ? (
                                  <Textarea
                                    name=""
                                    className=""
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    placeholder=""
                                    _focus={{ zIndex: "0" }}
                                  ></Textarea>
                                ) : cell.column.id === "creator_payout" ? (
                                  <Textarea
                                    name=""
                                    className=""
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    placeholder=""
                                    _focus={{ zIndex: "0" }}
                                  ></Textarea>
                                ) : cell.column.id === "manager_payout" ? (
                                  <Textarea
                                    name=""
                                    className=""
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    placeholder=""
                                    _focus={{ zIndex: "0" }}
                                  ></Textarea>
                                ) : cell.column.id === "live_links" ? (
                                  <Textarea
                                    name="live_links"
                                    className=""
                                    style={{
                                      resize: "both",
                                      minWidth: "200px",
                                    }}
                                    fontSize="sm"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;

                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                        deal_id: row.original.deal_id,
                                        influencer: row?.original?.influencer,
                                      });
                                      setInfluencerData((prevNewAddDeals) => {
                                        // Find the index of the row to update
                                        const index = prevNewAddDeals.findIndex(
                                          (ro) => ro?.row_Id == row.id
                                        );

                                        // If the row is found, update it
                                        if (index !== -1) {
                                          // Create a new array with the updated row
                                          const updatedNewAddDeals = [
                                            ...prevNewAddDeals,
                                          ];
                                          updatedNewAddDeals[index] = {
                                            ...updatedNewAddDeals[index],
                                            [fieldName]: value,
                                          };

                                          // Return the updated array to update the state
                                          return updatedNewAddDeals;
                                        }

                                        // If the row is not found, return the previous state
                                        return prevNewAddDeals;
                                      });
                                    }}
                                    onBlur={() => handleCellBlur()}
                                    icon={<MdArrowDropDown />}
                                    placeholder="live link "
                                    _focus={{ zIndex: "0" }}
                                  ></Textarea>
                                ) : cell.column.id === "brand_agency" ? (
                                  <Menu>
                                    <Box>
                                      <MenuGroup>
                                        <InputGroup>
                                          <Input
                                            name="brand_agency"
                                            className="custom-dropdown"
                                            style={{
                                              resize: "both",
                                              minWidth: "200px",
                                            }}
                                            fontSize="sm"
                                            value={row?.original?.brand_agency}
                                            onChange={(e) => {
                                              const fieldName = cell.column.id;
                                              const value = e.target.value;

                                              row.values[fieldName] = value;
                                              setInputCompanyValue(
                                                e.target.value
                                              );
                                              // const FinalComapy= selectedCompanies.join(" ")
                                              setEditedRowData({
                                                ...editedRowData,
                                                [fieldName]: value,
                                                deal_id: row.original.deal_id,
                                                influencer:
                                                  row?.original?.influencer,
                                              });

                                              setInfluencerData(
                                                (prevNewAddDeals) => {
                                                  if (index !== -1) {
                                                    // Create a new array with the updated row
                                                    const updatedNewAddDeals = [
                                                      ...prevNewAddDeals,
                                                    ];
                                                    updatedNewAddDeals[index] =
                                                      {
                                                        ...updatedNewAddDeals[
                                                          index
                                                        ],
                                                        [fieldName]: value,
                                                      };

                                                    // Return the updated array to update the state
                                                    return updatedNewAddDeals;
                                                  }

                                                  // If the row is not found, return the previous state
                                                  return prevNewAddDeals;
                                                }
                                              );

                                              setIsCompanySearchLoading(true);
                                            }}
                                            // onBlur={() => handleCellBlur()}

                                            // icon={<MdArrowDropDown />}
                                            placeholder="Select a Deal"
                                            _focus={{ zIndex: "0" }}
                                          ></Input>
                                          {inputCompanyValue ? (
                                            <InputRightElement>
                                              {isCompanySearchLoading ? (
                                                <Spinner
                                                  w={4}
                                                  h={4}
                                                  color="gray.400"
                                                />
                                              ) : (
                                                <CloseIcon
                                                  cursor={"pointer"}
                                                  w={2.5}
                                                  h={2.5}
                                                  color="gray.400"
                                                  onClick={(e) => {
                                                    const fieldName =
                                                      cell.column.id;

                                                    setInputCompanyValue(
                                                      e.target.value
                                                    );
                                                    // const FinalComapy= selectedCompanies.join(" ")
                                                    setEditedRowData({
                                                      ...editedRowData,
                                                      [fieldName]: "",
                                                      brand_rep: "",
                                                      deal_id:
                                                        row.original.deal_id,
                                                      influencer:
                                                        row?.original
                                                          ?.influencer,
                                                    });

                                                    setInfluencerData(
                                                      (prevNewAddDeals) => {
                                                        if (index !== -1) {
                                                          // Create a new array with the updated row
                                                          const updatedNewAddDeals =
                                                            [
                                                              ...prevNewAddDeals,
                                                            ];
                                                          updatedNewAddDeals[
                                                            index
                                                          ] = {
                                                            ...updatedNewAddDeals[
                                                              index
                                                            ],
                                                            [fieldName]: "",
                                                            brand_rep: "",
                                                          };

                                                          // Return the updated array to update the state
                                                          return updatedNewAddDeals;
                                                        }

                                                        // If the row is not found, return the previous state
                                                        return prevNewAddDeals;
                                                      }
                                                    );
                                                  }}
                                                />
                                              )}
                                            </InputRightElement>
                                          ) : (
                                            <InputRightElement>
                                              <SearchIcon
                                                w={3}
                                                h={3}
                                                color="gray.400"
                                              />
                                            </InputRightElement>
                                          )}
                                        </InputGroup>
                                        {inputCompanyValue && (
                                          <Card mt={1}>
                                            {filteredCompanyData?.length >
                                              0 && (
                                              <Box
                                                my={1}
                                                maxH={"145px"}
                                                overflowY={"auto"}
                                                sx={{
                                                  "&::-webkit-scrollbar": {
                                                    width: "4px",
                                                  },
                                                  "&::-webkit-scrollbar-thumb":
                                                    {
                                                      background: "#dddddd",
                                                      borderRadius: "100vh",
                                                    },
                                                }}
                                              >
                                                {filteredCompanyData?.map(
                                                  (company, i) => (
                                                    <Text
                                                      px={3}
                                                      py={1}
                                                      fontSize={"sm"}
                                                      _hover={{
                                                        bg: "#EDF2F7",
                                                        cursor: "pointer",
                                                      }}
                                                      key={i}
                                                      onClick={() =>
                                                        handleRowCompanyClick(
                                                          company,
                                                          index,
                                                          "brand_agency",
                                                          row,
                                                          row.cells[ind + 1]
                                                        )
                                                      }
                                                    >
                                                      {company}
                                                    </Text>
                                                  )
                                                )}
                                              </Box>
                                            )}
                                          </Card>
                                        )}
                                      </MenuGroup>
                                    </Box>
                                  </Menu>
                                ) : cell.column.id === "date_received" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      <Input
                                        type="date"
                                        label="Select Recieved Date"
                                        // selectedDate={selectedDate}
                                        value={selectedDate}
                                        onChange={(e) => {
                                          const fieldName = cell.column.id;
                                          const value = e.target.value;

                                          row.values[fieldName] = value;
                                          setSelectedDate(e.target.value);
                                          setEditedRowData({
                                            ...editedRowData,
                                            [fieldName]:
                                              value === "" ? null : value,
                                            deal_id: row.original.deal_id,
                                            influencer:
                                              row?.original?.influencer,
                                          });
                                        }}
                                        onBlur={() => handleCellBlur()}
                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : cell.column.id === "live_date" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      {/* {row.original.live_date} */}
                                      <Input
                                        type="date"
                                        label="Select Live Date"
                                        // selectedDate={selectedDate}
                                        value={selectedDate}
                                        onChange={(e) => {
                                          const fieldName = cell.column.id;
                                          const value = e.target.value;

                                          row.values[fieldName] = value;
                                          setSelectedDate(e.target.value);

                                          setEditedRowData({
                                            ...row.original,
                                            [fieldName]:
                                              value === "" ? null : value,
                                            deal_id: row.original.deal_id,
                                            influencer:
                                              row?.original?.influencer,
                                          });
                                        }}
                                        onBlur={() => handleCellBlur()}
                                        // autoFocus={true}

                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : cell.column.id === "rough_draft_date" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      <Input
                                        type="date"
                                        label="Select Rough draft Date"
                                        // selectedDate={selectedDate}
                                        value={selectedDate}
                                        onChange={(e) => {
                                          const fieldName = cell.column.id;
                                          const value = e.target.value;

                                          row.values[fieldName] = value;
                                          setSelectedDate(e.target.value);
                                          setEditedRowData({
                                            ...editedRowData,
                                            [fieldName]:
                                              value === "" ? null : value,
                                            deal_id: row.original.deal_id,
                                            influencer:
                                              row?.original?.influencer,
                                          });
                                        }}
                                        onBlur={() => handleCellBlur()}
                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : cell.column.id === "invoice_date" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      <Input
                                        type="date"
                                        label="Select Invoice Date"
                                        // selectedDate={selectedDate}
                                        value={
                                          selectedDate
                                            ? selectedDate
                                            : InvoiceDate
                                        }
                                        onChange={(e) => {
                                          const fieldName = cell.column.id;
                                          const value = e.target.value;

                                          row.values[fieldName] = value;
                                          setSelectedDate(e.target.value);
                                          setEditedRowData({
                                            ...editedRowData,
                                            [fieldName]:
                                              value === "" ? null : value,
                                            deal_id: row.original.deal_id,
                                            influencer:
                                              row?.original?.influencer,
                                          });
                                        }}
                                        onBlur={() => handleCellBlur()}
                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : cell.column.id === "payout_date" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      <Input
                                        type="date"
                                        label="Payout Date"
                                        // selectedDate={selectedDate}
                                        value={selectedDate}
                                        onChange={(e) => {
                                          const fieldName = cell.column.id;
                                          const value = e.target.value;

                                          row.values[fieldName] = value;
                                          setSelectedDate(e.target.value);
                                          setEditedRowData({
                                            ...editedRowData,
                                            [fieldName]:
                                              value === "" ? null : value,
                                            deal_id: row.original.deal_id,
                                            influencer:
                                              row?.original?.influencer,
                                          });
                                        }}
                                        onBlur={() => handleCellBlur()}
                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : cell.column.id === "date_receiver" ? (
                                  <FormControl>
                                    <Box p={4}>
                                      <ChakraDatePicker
                                        label="Select Receiver Date"
                                        selectedDate={selectedDate}
                                        onChange={handleDateChange}
                                        onBlur={() => handleCellBlur()}
                                        name={cell.column.id}
                                        row={row}
                                      />
                                    </Box>
                                  </FormControl>
                                ) : (
                                  <Textarea
                                    style={{
                                      resize: "both",
                                      minWidth: "250px",
                                    }}
                                    // w="250px"
                                    type="text"
                                    value={row.values[cell.column.id]}
                                    onChange={(e) => {
                                      const fieldName = cell.column.id;
                                      const value = e.target.value;
                                      if (
                                        fieldName === "meta_tags" ||
                                        fieldName ===
                                          "whitelisted_company_domains" ||
                                        fieldName === "partners_domain_name"
                                      ) {
                                        const metaTagsArray = value
                                          .split(",")
                                          .map((tag) => tag);
                                        row.values[fieldName] = metaTagsArray;
                                        setEditedRowData({
                                          ...editedRowData,
                                          [fieldName]: metaTagsArray,
                                        });
                                      } else {
                                        row.values[fieldName] = value;
                                        setEditedRowData({
                                          ...editedRowData,
                                          [fieldName]: value,
                                          deal_id: row.original.deal_id,
                                          influencer: row?.original?.influencer,
                                        });
                                        setInfluencerData((prevNewAddDeals) => {
                                          // Find the index of the row to update
                                          const index =
                                            prevNewAddDeals.findIndex(
                                              (ro) => ro?.row_Id == row.id
                                            );

                                          // If the row is found, update it
                                          if (index !== -1) {
                                            // Create a new array with the updated row
                                            const updatedNewAddDeals = [
                                              ...prevNewAddDeals,
                                            ];
                                            updatedNewAddDeals[index] = {
                                              ...updatedNewAddDeals[index],
                                              [fieldName]: value,
                                            };

                                            // Return the updated array to update the state
                                            return updatedNewAddDeals;
                                          }

                                          // If the row is not found, return the previous state
                                          return prevNewAddDeals;
                                        });
                                      }
                                    }}
                                    onBlur={() => handleCellBlur()} // Auto-save on input blur
                                  />
                                )
                              ) : (
                                cell.render("Cell")
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Flex alignItems={"center"} justifyContent={"space-between"} className="all_deal_page_cont">
            <Flex>
              Page&nbsp;
              <Text fontWeight={500}>
                {pageIndex + 1} of {pageCount}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={3}>
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronLeft
                  size={24}
                  onClick={() => previousPage()}
                  disabled={pageIndex === 0}
                />
              </Box>
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronRight
                  size={24}
                  onClick={() => nextPage()}
                  disabled={pageIndex === pageCount - 1}
                />
              </Box>
            </Flex>
          </Flex>
        </SkeletonText>

        <DealsDeleteDialogBox
          isOpen={isOpenDealsDeleteDialogBox}
          onClose={() => {
            setIsOpenDealsDeleteDialogBox(false);
          }}
          isLoading={isLoading}
          selectedFlatRows={selectedFlatRows}
          handleDeleteRows={handleDeleteRows}
        />

        <CompanyFormModal
          isOpen={isOpenCompanyFormModal}
          onClose={() => {
            setIsOpenCompanyFormModal(false);
          }}
        />

        <CreateProspectModal
          isOpen={isOpenProspectFormModal}
          onClose={() => {
            setIsOpenProspectFormModal(false);
            // setProspectUpDelete(true)
            // fetchCompanyData();
          }}
        />
        <InfluencerMailModel
          isOpen={isOpenInfluencerMailModel}
          onClose={() => setIsOpenInfluencerMailModel(false)}
          data={selectedFlatRows}
        />

        <DealsFollowConfirmModel
          isOpen={isOpenDealsFollowConfirmModel}
          onClose={() => setisOpenDealsFollowConfirmModel(false)}
          rowData={rowData}
          talent_name={talent_name}
          onhandleState={handleState}
          fetchAllInfluencerDealsData={fetchAllInfluencerDealsData}
          //  data ={selectedFlatRows}
        />
      </Box>
      <ClockModel
        isOpen={isOpenClockModel}
        onClose={() => setIsOpenClockModel(false)}
        clockDate={clockDate}
        DeleteFolowUp={DeleteFolowUp}
      />
      {/* <MemoKol setPrintButton={setPrintButton} /> */}

      {printButton && (
        <Invoice
          isOpen={isOpenInvoice}
          onClose={() => setisOpenInvoice(false)}
          brandForInvoice={brandForInvoice}
          DealAmountForInvoice={DealAmountForInvoice}
          BrandRepForInvoice={BrandRepForInvoice}
          tal_name={talent_name}
          DeliverablsForInvoice={DeliverablsForInvoice}
          LinkForInvoice={LinkForInvoice}
          LoctionForInvoice={LoctionForInvoice}
          MailForInvoice={MailForInvoice}
          InvoiceForInvoice={InvoiceForInvoice}
          DealIdForInvoice={DealIdForInvoice}
          onDateprops={Dateprops}
          PaymentTermForInvoice={PaymentTermForInvoice}
          fetchAllInfluencerDealsData={fetchAllInfluencerDealsData}
          setActiveDealsTab={setActiveDealsTab}
          activeDealsTab={activeDealsTab}
        />
      )}
    </>
  );
};

export default AllDealsTab;
