import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  FormControl,
  FormLabel,
  Divider,
  useToast,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
} from "@chakra-ui/react";
import GmailAuth from "../../GmailAuth";
import { Switch } from "@chakra-ui/react";
import EditTemplateModel from "./EditTemplateModel";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import "./InboxCardModel.css";
import CreateNewEmailTemplate from "./CreateNewEmailTemplate";

const InboxEmailsModel = ({ isOpen, onClose, autoRespondStatus }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("Role");
  const [TemplateData, setTemplateData] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const [isOpenNewTemplate, setIsOpenNewTemplate] = useState(false);
  const [name, setName] = useState("hi");
  const [body, setBody] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [editedKeyword, setEditedKeyword] = useState("");
  const [emailTemplateData, setEmaiTemplateData] = useState([]);
  const [isTemplateData, setIsTemplateData] = useState(false);
  const inputRef = useRef(null);
  const toast = useToast();

  const fetchTemplates = async () => {
    try {
      // setIsCompanySearchLoading(true);
      //    /get-autorespond-template/
      const response = await fetch(`${BASE_URL}/get-autorespond-template/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTemplateData(data.data);

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      // setFilteredCompanyData(data?.companies);
      // setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEmailTemp = async () => {
    try {
      // setIsCompanySearchLoading(true);
      //    /get-autorespond-template/
      const response = await fetch(`${BASE_URL}/get-composer-temp/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setEmaiTemplateData(data.data);

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      // setFilteredCompanyData(data?.companies);
      // setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
    fetchEmailTemp();
    setIsToggled(autoRespondStatus);
  }, [autoRespondStatus]);

  const handleToggle = async () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    try {
      const response = await fetch(`${BASE_URL}/auto-respond/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          value: newToggleState,
        }),
      });
    } catch (error) {
      console.error("Error hitting API:", error);
    }
  };

  const handleSetTemplat = (id, temp, temp2, isEditing) => {
    setIsTemplateData(isEditing);
    setIsOpenTemplate(true);
    setTemplateId(id);
    setName(temp);
    setBody(temp2);
  };

  const handleDoubleClick = (id, currentKeywords) => {
    setEditingTemplateId(id);
    setEditedKeyword(currentKeywords.join(", "));
  };

  const handleKeywordChange = (e) => {
    setEditedKeyword(e.target.value);
  };

  const handleSaveKeywords = async (id) => {
    const updatedKeywords = editedKeyword
      .split(",")
      .map((keyword) => keyword.trim());

    try {
      const response = await fetch(`${BASE_URL}/get-autorespond-template/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ temp_id: id, keywords: updatedKeywords }),
      });

      const data = await response.json();

      if (data.message === "Data updated successfully") {
        toast({
          title: "Data updated successfully",
          description: "Data updated successfully",
          status: "success",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
        // onClose();
      } else {
        toast({
          title: "Data updated not successfully",
          description: "Data updated not successfully",
          status: "error",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
      }

      // Update the local state with the new keywords
      setTemplateData((prevTemplates) =>
        prevTemplates.map((template) =>
          template.id === id
            ? { ...template, keywords: updatedKeywords }
            : template
        )
      );
    } catch (error) {
      console.error("Error updating keywords:", error);
    }

    setEditingTemplateId(null);
  };

  const handleCancelEdit = () => {
    setEditingTemplateId(null);
    setEditedKeyword("");
  };

  const DeleteEmailTemplates = async (temp_id) => {
    try {
      const response = await fetch(`${BASE_URL}/delete-comp-temp/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ temp_id }),
      });
      const data = await response.json();

      if (data.message === "Template deleted successfully") {
        toast({
          title: "Email Template successfully",
          description: data.message,
          status: "success",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
        await fetchEmailTemp();
      }
    } catch (e) {
      toast({
        title: "  ",
        description: "Some Thing went worng",
        status: "error",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (editingTemplateId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingTemplateId]);

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap={true}>
        <ModalOverlay />
        <ModalContent
          className="index_setting_popup"
          maxWidth="800px"
          minHeight="400px"
          width="90%"
          height="auto"
        >
          <ModalHeader>Inbox Settings</ModalHeader>
          <ModalCloseButton />
          <Divider className="inbox_template_divider" />
          <ModalBody pb={6}>
            <FormControl display="flex" alignItems="center">
              <FormLabel className="label_feat" htmlFor="email" mb="0">
                Auto Respond Feature
              </FormLabel>

              <Switch
                id="email-alerts"
                size="lg"
                position={"absolute"}
                right={0}
                isChecked={isToggled}
                onChange={handleToggle}
              />
            </FormControl>

            {TemplateData && TemplateData.length > 0 && (
              <>
                <FormLabel
                  className="label_heading"
                  htmlFor="email-alerts"
                  mb="0"
                >
                  Auto Respond Templates
                </FormLabel>
                {TemplateData.map((template, index) => (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    key={index}
                    mb={4}
                  >
                    <Box
                      className="index_inner"
                      htmlFor="template-keywords"
                      mb="0"
                      fontSize="lg"
                    >
                      {template.name}
                    </Box>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      ml={4}
                      position={"absolute"}
                      right={0}
                      onClick={() => {
                        handleSetTemplat(
                          template.id,
                          template.name,
                          template.body,
                          false
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </FormControl>
                ))}
              </>
            )}

            <FormLabel htmlFor="email-title" mb="0">
              Auto Respond Keywords
            </FormLabel>
            <p>
              Double click to edit the Keywords that you want to use to trigger
              the corresponding auto response,seprate each keyword with a comma
              ( , ) Click away from the edit box to save.
            </p>
            <br />
            {TemplateData &&
              TemplateData.map((template) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={`keywords-${template.id}`}
                  mb={4}
                >
                  <Box htmlFor="template-keywords" mb="0" fontSize="lg">
                    {template.name} Keyword
                  </Box>
                  {editingTemplateId === template.id ? (
                    userRole === "Admin" ? (
                      <input
                        className="keyword_input form-control"
                        ref={inputRef}
                        width="50%"
                        ml={4}
                        position={"absolute"}
                        right={0}
                        value={editedKeyword}
                        onChange={handleKeywordChange}
                        onBlur={() => handleSaveKeywords(template.id)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSaveKeywords(template.id);
                          } else if (e.key === "Escape") {
                            handleCancelEdit();
                          }
                        }}
                      />
                    ) : (
                      <span className="not-allowed">Editing not allowed</span>
                    )
                  ) : (
                    <span
                      className="pop_tags"
                      ml={4}
                      position={"absolute"}
                      right={0}
                      cursor={userRole === "Admin" ? "pointer" : "default"}
                      onDoubleClick={() => {
                        if (userRole === "Admin") {
                          handleDoubleClick(template.id, template.keywords);
                        }
                      }}
                    >
                      {template?.keywords?.join(", ")}
                    </span>
                  )}
                </FormControl>
              ))}
          </ModalBody>
          <Divider />
          <div className="template_footer">
            <div className="template_footer_header">
              <FormLabel htmlFor="email" className="template_header_text">
                Email Templates
              </FormLabel>
            </div>
            <div className="template_footer_body">
              {emailTemplateData && emailTemplateData.length > 0 ? (
                <>
                  <Box>
                    <TableContainer>
                      <Table
                        variant="simple"
                        size="sm"
                        className="template_footer_table"
                      >
                        <Tbody className="template_footer_tbody">
                          {emailTemplateData.map((template, index) => (
                            <Tr key={index}>
                              <Td className="template_footer_name_td p-ico">
                                {template.name}
                              </Td>
                              <Td className="template_footer_td p-ico">
                                {userRole === "Admin" && (
                                  <button
                                    className="template_body_edit_icon"
                                    onClick={() => {
                                      handleSetTemplat(
                                        template.id,
                                        template.name,
                                        template.body,
                                        true
                                      );
                                    }}
                                  >
                                    <MdModeEdit />
                                  </button>
                                )}
                              </Td>
                              <Td className="p-ico">
                                {userRole === "Admin" && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      DeleteEmailTemplates(template.id)
                                    }
                                  >
                                    <MdDelete color="red" fontSize={"15px"} />
                                  </button>
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              ) : (
                <p className="template_body_content">No Email Templates</p>
              )}
            </div>
            <div className="template_footer_button_area">
              <button
                type="button"
                className="template_footer_btn"
                onClick={() => setIsOpenNewTemplate(true)}
              >
                <span className="template_plus_icon">
                  <FiPlus />
                </span>
                Create New
              </button>
            </div>
          </div>
          {isOpenNewTemplate && (
            <CreateNewEmailTemplate
              isOpen={isOpenNewTemplate}
              onClose={() => setIsOpenNewTemplate(false)}
              fetchEmailTemp={fetchEmailTemp}
              fetchTemplates={fetchTemplates}
            />
          )}
          {isOpenTemplate && (
            <EditTemplateModel
              isOpen={isOpenTemplate}
              onClose={onClose}
              templateId={templateId}
              name={name}
              body={body}
              fetchEmailTemp={fetchEmailTemp}
              fetchTemplates={fetchTemplates}
              setIsOpenTemplate={setIsOpenTemplate}
              isTemplateData={isTemplateData}
            />
          )}
        </ModalContent>
      </Modal>

    </>
  );
};

export default InboxEmailsModel;
