import React, { useState, useRef, useEffect } from "react";
import { IoMdNotifications } from "react-icons/io";
import { CloseButton, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

const NotificationPopup = ({
  notificationsData,
  handleNotifications,
  handleCross,
  formatNotificationType,
  counts
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const popupRef2 = useRef(null);


  const togglePopup = () => {
    handleNotifications();
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if(popupRef2.current && !popupRef2.current.contains(event.target)){
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  

  return (
    <div className="notification-container">
      <div onClick={() => { togglePopup() }} ref={popupRef2}>
        <IoMdNotifications size={28}/>
        <span className="number-icon">{counts}</span>
      </div>


      {isOpen && (
        <div className="noti-icon" ref={popupRef}>
          <div className="noti-arrow">{/* Optional arrow icon */}</div>
          <button className="noti-close-button" onClick={closePopup}>
            ×
          </button>
          <div className="noti-header">Notifications</div>
          <div className="noti-body">
            {notificationsData?.map((notification) => (
              <div key={notification.notification_id} className="noti-item">
                <CloseButton
                  onClick={() => handleCross(notification.notification_id)}
                />
                <Text>
                  {formatNotificationType(`${notification.notification_type}!`)}{" "}
                  {notification.message}
                </Text>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
