import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  Input,
  Divider,
  Textarea,
  useToast,
  Select
} from "@chakra-ui/react";
import { useNotification } from "../../services/NotificationContext";
import ReactQuill from "react-quill";
import "./emailReplyPopup.css";

const stripHtmlTags = (html) => {
  return html
    .replace(/<br\s*\/?>/g, "\n")
    .replace(/<[^>]*>/g, "")
    .replace(/&nbsp;/g, " ")
    .replace(/&bsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

const convertToHtmlFormat = (text) => {
  let formattedText = text.replace(/\n/g, "<br>").trim();
  formattedText = formattedText.replace(/\((.*?)\)/g, "<span>($1)</span>");
  formattedText = formattedText.replace(/\{(.*?)\}/g, "<span>{$1}</span>");
  return formattedText;
};

const EditTemplateModel = ({
  isOpen,
  onClose,
  templateId,
  name,
  body,
  fetchEmailTemp,
  fetchTemplates,
  setIsOpenTemplate,
  isTemplateData,
}) => {
  const [plainTextBody, setPlainTextBody] = useState(body || "");
  
  const [currentName, setCurrentName] = useState(name);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token"); 
  const {  handleEmailRead } = useNotification();
    const [keywords, setKeywords] = useState("");
    const [selectedKeyword, setSelectedKeyword] = useState("");

  const quillRef = useRef(null);

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: ["red", "blue", "yellow", "white", "orange", "green", "purple"] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
    },
  };

  const toast = useToast();
  const handleChange = (content) => {
    setPlainTextBody(content);
  };

  const handleKeywordsChange = (event) => {
    const value = event.target.value;
    if (value && quillRef.current) {
      setKeywords(value);
      // setTemplateContent((prevContent) => prevContent + value + " ");
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill?.getSelection()?.index;
      if(cursorPosition !== undefined) {
        quill.insertText(cursorPosition, value); 
        quill.setSelection(cursorPosition + value.length);
        setSelectedKeyword("");
      }
    }

  };

  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };

  const handleSave = async () => {
    const formattedBody = convertToHtmlFormat(plainTextBody);
    let url;
    let method;
    let bodyContent;

    if (isTemplateData) {
      url = `${BASE_URL}/get-composer-temp/`;
      method = "PUT";
      bodyContent = {
        id: templateId,
        body: formattedBody,
        name: currentName,
      };
    } else {
      url = `${BASE_URL}/get-autorespond-template/`;
      method = "PATCH";
      bodyContent = {
        temp_id: templateId,
        body: formattedBody,
        name: currentName,
        keywords: keywords.split(",").map((keyword) => keyword.trim()),
      };
    }

    try {
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(bodyContent),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      handleEmailRead();
      if (
        data.message === "Template updated successfully" ||
        data.message === "Data updated successfully"
      ) {
        setIsOpenTemplate(false);
        toast({
          title: "Data updated successfully",
          description: "Data updated successfully",
          status: "success",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
      setKeywords("");
        await fetchTemplates();
        await fetchEmailTemp();
        // onClose();
      } else {
        throw new Error("Data update failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Data update not successful",
        description: error.message || "Data update not successful",
        status: "error",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
      // onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpenTemplate(false)} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent style={{ maxWidth: "700px", height: "600px" }}>
          <ModalHeader>Edit Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={0}>
          <Flex gap={2} flexDirection={["column", "column", "row"]} justifyContent="space-between">
  <Flex className="edit-template-name" flex="1">
    <Box>Name</Box>
    <Input
      style={{
        margin: "-8px 6px",
      }}
      type="text"
      value={currentName}
      onChange={handleNameChange}
    />
  </Flex>
  <Flex >
    <Box mr={2}>Short Codes</Box>
    <Select
      mt={-2}
      className="email_template_keywords"
      width="200px"
      value={selectedKeyword}
      onChange={handleKeywordsChange}
    >
      <option value="">Select Short Codes</option>
      <option value="{name}">{"{name}"}</option>
      <option value="{talent_name}">{"{talent_name}"}</option>
      <option value="{media_kit}">{"{media_kit}"}</option>
      <option value="{custom_message_X}">{"{custom_message_X}"}</option>
    </Select>
  </Flex>
</Flex>

            <Divider />
            <Box mt={6}>
              {/* <Textarea
                value={plainTextBody}
                width="100%"
                height="250px"
                onChange={handleChange}
              /> */}
              <div  className="create-new-template edit-template">
                <ReactQuill
                  ref={quillRef}
                  value={plainTextBody}
                  onChange={handleChange}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  className="quill-editor"
                  scrollingContainer=".ql-editor"
                />
              </div>
            </Box>
          </ModalBody>

          <ModalFooter mb={4} pt={0}>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => setIsOpenTemplate(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditTemplateModel;
