import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Input, Button, FormLabel, Box, Divider } from '@chakra-ui/react'
import React from 'react'

const MinimumMatchDialogBox = ({ isOpen, onClose, value, setMatchValue, handleCampaignRecreation }) => {
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
            <AlertDialogContent className='campaign-regenerate'>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Campaign Regeneration Settings</AlertDialogHeader>
                <AlertDialogBody className='campain-regenerate'>
                    <Box display="flex" alignItems="center" mb={"2"} justifyContent="space-between" gap="10px">
                        <FormLabel mb="0">Set the minimum match score:</FormLabel>
                        <Input 
                            type="number" 
                            placeholder="Enter minimum match score" 
                            width="100%"
                            maxW={"150px"} 
                            borderRadius="7px" 
                            border="1px solid #ccc" 
                            value={value?.match_score} 
                            onChange={(e) => setMatchValue((prev) => ({ ...prev, match_score: e.target.value }))}  
                            style={{border: "1px solid #4682b4"}}
                        />
                    </Box>
                    <Divider />

                    <Box display="flex" alignItems="center" mt={"2"} mb={"2"} justifyContent="space-between" gap="10px">
                        <FormLabel mb="0">Set number of companies to test:</FormLabel>
                        <Input type='number' placeholder='Enter number of companies' width="100%" maxW={"150px"} borderRadius={"7px"} value={value?.company_count} onChange={(e) => setMatchValue((prev) => ({ ...prev, company_count: e.target.value }))}  style={{border: "1px solid #4682b4" }}/>
                    </Box>
                    <Divider />

                    <Box display="flex" alignItems="center" mt={"2"} justifyContent="space-between" gap="10px" px={"0"}>
                        <FormLabel mb="0">Set number of campaigns to generate:</FormLabel>
                        <Input type='number' placeholder='Enter minimum campaigns number'width="100%" maxW={"150px"}  borderRadius={"7px"} value={value?.campaign_count} onChange={(e) => setMatchValue((prev) => ({ ...prev, campaign_count: e.target.value }))} style={{border: "1px solid #4682b4"}} />
                    </Box>
                <AlertDialogFooter px={0}>
                    <Button size={"sm"} onClick={onClose}>Cancel</Button>
                    <Button size={"sm"} colorScheme='green'  loadingText="Deleting" spinnerPlacement='end' onClick={handleCampaignRecreation} ml={3}>Generate</Button>
                </AlertDialogFooter>
                </AlertDialogBody>
                
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default MinimumMatchDialogBox