import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { PulseLoader } from "react-spinners";

const ScoutCreaterToInfluncerDialogBox = ({
  isOpen,
  onClose,
  selectedFlatRows,
  isLoading,
  handleAddInfluencer,
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Creator Convert
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to convert Scout to Influencer?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button size={"sm"} onClick={onClose}>
              No
            </Button>
            <Button
              size={"sm"}
              colorScheme="blue"
              isLoading={isLoading}
              spinner={<PulseLoader size={8} color="white" />}
              loadingText=""
              spinnerPlacement="end"
              onClick={() => handleAddInfluencer(selectedFlatRows)}
              ml={3}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ScoutCreaterToInfluncerDialogBox;
