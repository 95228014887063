import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Spinner,
  Box,
  useColorMode,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { LuImport } from "react-icons/lu";
import { CloseIcon } from "@chakra-ui/icons";
import axios from "axios";

const ScoutingPopup = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState({});
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchScoutingListData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/prosperty-search/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching scouting data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchScoutingListData();
    }
  }, [isOpen]);

  const handleImport = async (item) => {
    try {
      setButtonLoading((prev) => ({ ...prev, [item.search_id]: true }));
      const res = await axios.get(`${BASE_URL}/prosperty-prospects-search/?search_id=${item?.search_id}&list_name=${item?.title}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      onClose();
      toast({
        title: "Import Scouting List",
        description: res.data.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Import failed:", error);
    } finally {
      setButtonLoading((prev) => ({ ...prev, [item.search_id]: false }));
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={["full", "full", "3xl", "4xl", "5xl"]} 
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius={3} marginY={2}>
        <Flex justify="space-between" align="center" paddingY={2} paddingX={4}>
          <ModalHeader
            flex="1"
            textAlign="left"
            m={0}
            py={1}
            px={0}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight="normal"
            width="100%"
          >
            Import Scouting List
          </ModalHeader>
          <IconButton
            icon={<CloseIcon color={"red"} />}
            size="sm"
            onClick={onClose}
            aria-label="Close"
            bg="transparent"
            _hover={{
              size: "md", 
            }}
            transition="all 0.3s ease"
            _active={{ bg: "transparent" }}
          />
        </Flex>
        <ModalBody 
          className="scout-list-table"
          p={2}
        >
          <Box 
            maxHeight={["80vh", "80vh", "70vh"]} 
            overflowY="auto" 
            display="block" 
            height="auto"
          >
            <Table
              variant="simple"
              border="1px solid"
              borderColor="gray.200"
              width="100%"
            >
              <Thead bg="#3182ce" className="fix-header">
                <Tr>
                  <Th width="100%" color="#ffffff">
                    List Name
                  </Th>
                  <Th width="100%" color="#ffffff">
                    Import
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Tr>
                    <Td colSpan={2}>
                      <Flex justify="center" align="center" height="100px">
                        <Spinner size="lg" />
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  <>
                    {data?.map((item) => (
                      <Tr 
                        key={item.search_id}
                        _hover={{
                          bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        <Td>{item?.title}</Td>
                        <Td>
                          {
                            !item?.status &&
                            <Button
                              size="sm"
                              colorScheme="blue"
                              onClick={() => {
                                !buttonLoading[item.search_id] && handleImport(item)
                              }}
                              px={5}
                              py={2}
                              fontSize={20}
                              rightIcon={buttonLoading[item.search_id] ?  <Spinner size="sm" /> :<LuImport />}
                            ></Button>
                          }
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ScoutingPopup;
