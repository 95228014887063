import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  IconButton,
  Flex,
  Box,
  Text,
  Select,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { MdArrowDropDown } from "react-icons/md";
import "./Sequences.css";
import axios from "axios";
import { debounce } from "lodash";

const SequenceSetting = ({ isOpen, onClose, sequenceId, autoSend, setAutoSend }) => {
  const toast = useToast();
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState({
    getLoading: false,
    saveLoading: false,
    deleteLoading: false,
    getList: false,
  });
  const [sendEmailSequence, setSendEmailSequence] = useState(autoSend);
  
  const [selectedSequecne, setSelectedSequecne] = useState(null);
  const [dropdownList, setDropdownList] = useState([]);
  const [isDeleteButtonShow, setIsDeleteButtonShow] = useState(false);

  const onsubmit = async (selectedSequecne) => {
    if (!selectedSequecne || selectedSequecne === null) {
      toast({
        title: "Update Sequence",
        description: `Please select a sequence`,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      setLoading((prev) => ({ ...prev, saveLoading: true }));
      const response = await axios.post(
        `${BASE_URL}/add-default-sequence/${sequenceId}/${selectedSequecne}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast({
        title: "Update Sequence",
        description: `${response?.data?.message ?? "succes"}`,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("error while submitting: ", error.message);
    } finally{
      setLoading((prev) => ({ ...prev, saveLoading: false }));
    }
  };

  const onDeleteSequence = async (selectedSequecne) => {
    if (!selectedSequecne) {
      return;
    }
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      const response = await axios.post(
        `${BASE_URL}/remove-def-seq/`,
        {
          sequence_id: sequenceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSelectedSequecne(null);
      onClose();
      toast({
        title: "Delete Sequence",
        description: `${
          response?.data?.message ?? "sequecne deleted successfully"
        }`,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("error while submitting: ", error.message);
    } finally {
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const sendApiRequest = useCallback(
    debounce(async (value) => {
      try {
        await axios.put(
          `${BASE_URL}/auto-sequence-status/`,
          { auto_send:value, sequence_id:sequenceId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Error hitting API:", error);
        toast({
          title: "Failed To update",
          description: error.message,
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    }, 600),
    [sequenceId]
  );

  const handleToggleSequence = async () => {
    const toggleState = !sendEmailSequence;
    // setSendEmailSequence(toggleState);
    setAutoSend(toggleState);
    sendApiRequest(toggleState);
  };

  // useEffect(()=>{
  //   if(isOpen){
  //   const getToggleSequence = async (sequenceId) => {
  //     try {
  //       setLoading((prev) => ({ ...prev, getLoading: true }));
  //       const response = await axios.get(
  //         `${BASE_URL}/auto-sequence-status/`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

        
  //       if(response?.data?.single_thread){
  //       setSendEmailSequence(response.data.single_thread)
  //     }
        
  //     } catch (error) {

  //       console.error("error fetch sequence data: ", error.message);
  //     } finally {
  //       setLoading((prev) => ({ ...prev, getLoading: false }));
  //     }
  //   };
  
  //   getToggleSequence(sequenceId)
  // }

  // },[isOpen])

  useEffect(() => {
    setSendEmailSequence(autoSend)
  }, [autoSend])

  useEffect(() => {
    const getSequence = async (sequenceId) => {
      try {
        setLoading((prev) => ({ ...prev, getLoading: true }));
        const response = await axios.get(
          `${BASE_URL}/get-default-sequence/${sequenceId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedSequecne(response?.data?.influencer_id ?? null);
        setIsDeleteButtonShow(response?.data?.influencer_id ? true : false);
      } catch (error) {
        setSelectedSequecne(null);
        setIsDeleteButtonShow(false);
        console.error("error fetch sequence data: ", error.message);
      } finally {
        setLoading((prev) => ({ ...prev, getLoading: false }));
      }
    };

    const getSequenceDropDown = async (sequenceId) => {
      try {
        setLoading((prev) => ({ ...prev, getList: true }));
        const response = await axios.get(
          `${BASE_URL}/get-seq-talent-name/${sequenceId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDropdownList(response?.data?.influencers ?? []);
      } catch (error) {
        console.error("error fetch sequence dropdown data: ", error.message);
      } finally {
        setLoading((prev) => ({ ...prev, getList: false }));
      }
    };

    if (sequenceId && isOpen) {
      getSequence(sequenceId);
      getSequenceDropDown(sequenceId);
    }
  }, [sequenceId, isOpen, token, BASE_URL]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={["md", "lg", "xl", "xl", "2xl"]}
      isCentered
    >
      <ModalOverlay />
      <ModalContent position={"relative"} borderRadius={3} marginY={2} className="center-popup-all">
        {(loading.getLoading || loading.getList) && (
          <Flex position={"absolute"} backgroundColor={"#00000021"} zIndex={"99"} justify="center" align="center" top={"0px"} left={"0px"} right={"0px"} bottom={"0px"}>
            <Spinner color="#36378d" size="lg" />
          </Flex>
        )}
        <Flex
          justify="space-between"
          align="center"
          paddingY={2}
          paddingX={4}
          borderBottom="1px solid rgba(210, 213, 214, 0.45)"
          className="center-popup-all4"
        >
          <ModalHeader
            flex="1"
            textAlign="left"
            m={0}
            py={1}
            px={0}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={22}
            fontWeight="bold"
            width="100%"
          >
            Sequence Settings
          </ModalHeader>
          <IconButton
            icon={<CloseIcon color={"black"} fontSize={11} />}
            size="sm"
            onClick={onClose}
            aria-label="Close"
            bg="transparent"
            _hover={{
              size: "md",
            }}
            transition="all 0.3s ease"
            _active={{ bg: "transparent" }}
          />
        </Flex>
        <ModalBody className="scout-list-table" p={2}>
          <Box
            maxHeight={["80vh", "80vh", "70vh"]}
            overflowY="auto"
            display="block"
            height="auto"
          >
            <Flex
              justify="space-between"
              align="center"
              paddingY={2}
              paddingLeft={7}
              paddingRight={4}
              direction="column"
            >
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email" mb="0" fontSize="md" fontWeight="bold">
                  Send all emails in a single thread
                </FormLabel>

                <Switch
                  id="email-alerts"
                  size="lg"
                  position={"absolute"}
                  right={0}
                  isChecked={sendEmailSequence}
                  onChange={() => handleToggleSequence()}
                />
              </FormControl>
              <Flex
                justify="space-between"
                w="100%"
                align="center"
                paddingTop={6}
                paddingBottom={2}
              >
                <Box>
                  <Text fontSize="md" fontWeight="bold">
                    Set Default Sequence For Creator
                  </Text>
                  <Text fontSize="xs" color="gray.500" mb={4}>
                    Used to auto-approve outreach campaigns
                  </Text>
                </Box>

                <Box display="flex" flexDirection="column" gap={3}>
                  <Select
                    name="creatorType"
                    fontSize="sm"
                    value={selectedSequecne}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedSequecne(value);
                    }}
                    icon={<MdArrowDropDown />}
                    placeholder="Select Creator"
                    _focus={{ zIndex: "0" }}
                  >
                    {/* <option value="">Pending</option> */}
                    {dropdownList?.map((dropdownValue) => (
                      <option
                        key={dropdownValue.influencer_id}
                        value={dropdownValue.influencer_id}
                      >
                        {dropdownValue.talent_name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
            </Flex>
            <Flex
              justify="end"
              align="center"
              paddingBottom={2}
              paddingX={4}
              gap={2}
            >
              {isDeleteButtonShow && (
                <Button
                  size="sm"
                  isLoading={loading.deleteLoading}
                  onClick={() => {
                    !loading.deleteLoading && onDeleteSequence(selectedSequecne);
                  }}
                  colorScheme="red"
                >
                  Delete
                </Button>
              )}
              <Button
                size="sm"
                isLoading={loading.saveLoading}
                onClick={() => {
                  !loading.saveLoading && onsubmit(selectedSequecne);
                }}
                colorScheme="blue"
              >
                Save
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SequenceSetting;
