import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { MdPersonSearch } from "react-icons/md";
import axios from "axios";

const ProspectLinkedInFormModal = ({ isOpen, onClose, companyID }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [urls, setUrls] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Add this useEffect
  useEffect(() => {
    if (isSubmitted && profiles.length > 0) {
      const enteredUrls = urls.filter(url => url.trim() !== "");
      const allValid = enteredUrls.every(url => isValidUrl(url));
      
      if (allValid) {
        onClose();
        setIsSubmitted(false);
      }
    }
  }, [profiles, isSubmitted, urls, onClose]);

  // Rest of the component remains the same
  const handleChange = (index, value) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const handleSubmit = async () => {
    if (urls.every((url) => url.trim() === "")) {
      toast({
        title: "Validation Error",
        description: "Please enter at least one LinkedIn URL.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/get_linkedin_profile_info/`,
        {
          linkedin_url: urls.filter((url) => url.trim() !== ""),
          company_id: companyID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      toast({
        title: "Success",
        description: "LinkedIn URLs submitted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitted(true);
      setProfiles(response?.data?.profiles || []);
    } catch (error) {
      toast({
        title: "Failed",
        description: "Error submitting LinkedIn URLs.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isValidUrl = (inputUrl) => {
    const trimmedUrl = inputUrl.trim();
    if (!trimmedUrl) return false;
    const profile = profiles.find(
      (profile) => profile.linkedin_url === trimmedUrl
    );
    return profile?.status_code === 200;
  };

  useEffect(() => {
    setUrls(Array(7).fill(""));
  }, [isOpen]);

  return (
    <Box>
      <Modal size="6xl" isOpen={isOpen} onClose={() => {
        onClose();
        setIsSubmitted(false);
      }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="500" fontSize="2xl">
            <Flex alignItems="center" gap={2}>
              <MdPersonSearch size={28} />
              New Prospect
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {urls.map((url, index) => (
                <Box position="relative" key={index} mb={4}>
                  <Input
                    name={`linkedInUrl${index}`}
                    type="text"
                    placeholder="Enter LinkedIn URL"
                    value={url}
                    onChange={(e) => handleChange(index, e.target.value)}
                    pr="8"
                    className="linkedin-prospect"
                  />
                  {url.trim() !== "" && (
                    <Box
                      position="absolute"
                      right="4"
                      top="50%"
                      transform="translateY(-50%)"
                    >
                      {isSubmitted && (isValidUrl(url) ? (
                        <CheckIcon color="green.500" boxSize={5} />
                      ) : (
                        <CloseIcon color="red.500" boxSize={5} />
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
              <Flex gap={5} mb={4}>
                <Button
                  isLoading={isLoading}
                  spinner={<PulseLoader size={8} color="white" />}
                  loadingText="Submitting"
                  colorScheme="green"
                  mt={8}
                  ml="auto"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProspectLinkedInFormModal;