import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Input,
  Flex,
  Text,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Modal, 
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useMediaQuery,
} from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineAttachFile, MdFormatColorText } from "react-icons/md";
import ReactQuill from "react-quill";
import EmojiPicker from "emoji-picker-react";
import { HiTemplate } from "react-icons/hi";
import "./EmailComposer.css";
import { IoClose } from "react-icons/io5";
import { tr } from "date-fns/locale";

export default function EmailComposer({ isEmailComposer, onClose, to="", type="" }) {
  const [emailContent, setEmailContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState(to);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emailTemplateData, setEmaiTemplateData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState("");
  const [ccMail, setccMail] = useState();
  const [bccMail, setbccMail] = useState();
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const toast = useToast();
  const quillRef = useRef(null);
  const popoverRef = useRef(null); 
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("userEmail");
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const [isClassActive, setIsClassActive] = useState(false)
  const [isMobile] = useMediaQuery("(max-width: 768px)");


  const fetchEmailTemp = async () => {
    try {
      // setIsCompanySearchLoading(true);
      //    /get-autorespond-template/
      const response = await fetch(`${BASE_URL}/get-composer-temp/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setEmaiTemplateData(data.data);

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      // setFilteredCompanyData(data?.companies);
      // setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmailTemp();
  }, []);

  const handleEditorChange = (content) => {
    setEmailContent(content);
    if (!content || !content.trim()) {
      setIsEmailEmpty("The email message cannot be empty.");
    } else {
      setIsEmailEmpty("");
    }
  };

  const formatEmailContent = (content) => {
    let formattedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "<br/>");

    formattedContent = formattedContent.replace(/<br(?!\/)>/g, "");
    formattedContent = formattedContent.replace(
      /(<br\/>)(?=\s*<br\/>)/g,
      "<br/>"
    );
    formattedContent = formattedContent.trim();
    if (formattedContent.endsWith("<br/>")) {
      formattedContent = formattedContent.slice(0, -5);
    }
    return formattedContent;
  };

  const handleSendEmail = async () => {
    const formattedContent = formatEmailContent(emailContent);
    if (!recipientEmail || !recipientEmail.trim()) {
      setIsEmailEmpty("Please enter email.");
      return;
    } else if (!subject || !subject.trim()) {
      setIsEmailEmpty("Please enter subject.");
      return;
    } else if (!formattedContent || !formattedContent.trim()) {
      setIsEmailEmpty("Email message is required!");
      return;
    }

    const formData = new FormData();
    if(type==="prospect"){
      formData.append("email", recipientEmail);
      formData.append("subject", subject);
      formData.append("message", formattedContent);
    } else {
      formData.append("to", recipientEmail);
      formData.append("sender", userEmail);
      formData.append("cc", ccMail ? ccMail : "");
      formData.append("bcc", bccMail ? bccMail : "");
      formData.append("subject", subject);
      formData.append("message_text", formattedContent);
    }


    if (file) {
      formData.append("file", file);
    }

    try {
      setLoading(true)
      let response 
      if(type==="prospect"){
        response = await fetch(`${BASE_URL}/send-prospect-email/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      } else {
        response = await fetch(`${BASE_URL}/create-inbox-message/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast({
        title: "Email sent.",
        description: "Your email reply has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toast({
        title: "Error sending email.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
      toast({
        title: "File uploaded.",
        description: `${uploadedFile.name} has been added.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddEmoji = (emojiData, event) => {
    event.stopPropagation();

    const emoji = emojiData.emoji;
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill?.getSelection()?.index || 0; 
    
    
  
    quill.insertText(cursorPosition, emoji); 
    quill.setSelection(cursorPosition + emoji.length);
  };

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: ["red", "blue", "yellow", "white", "orange", "green", "purple"] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const handleShowEmailsTemplates = () => {
    setOpenPopup(true);
  };

  const handleTemplateSelect = (templateName, templateBody) => {
    const updatedContent = templateBody.replace(
      /<strong>(.*?)<\/strong>/g,
      "$1"
    );
    setEmailContent(updatedContent);
    setOpenPopup(false);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const ShowFormattingText = () => {
    const toolbar = document.querySelector(".quill-editor .ql-toolbar");
    if (window.innerWidth <= 768) {
      if (toolbar) {
        const currentDisplay = getComputedStyle(toolbar).display;
        if (currentDisplay === "none") {
          toolbar.style.display = "";
          setIsClassActive(true)
        } else {
          toolbar.style.display = "none";
          setIsClassActive(false)
        }
      }
    }
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     const toolbar = document.querySelector(".quill-editor .ql-toolbar");
  //     if (toolbar) {
  //       // Check window width and toggle toolbar visibility
  //       if (window.innerWidth <= 768) {
  //         toolbar.style.display = "none"; // Hide toolbar for small screens
  //       } else {
  //         toolbar.style.display = ""; // Show toolbar for larger screens
  //       }
  //     }
  //   };

  //   // Attach resize event listener
  //   window.addEventListener("resize", handleResize);

  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const handleCCClick = () => setShowCC(true);
  const handleBCCClick = () => setShowBCC(true);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowEmojiPicker(false); 
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setRecipientEmail(to);
  }, [to])

  return (
    <>
      <div className="modal-email-composer">
        <Box
          maxW="1400px"
          height="100%"
          mx="auto"
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          backgroundColor="#ffffff"
          position="relative"
        >
          <div
            className="email_composer_headers mb-3 d-flex justify-content-between"
            style={{ backgroundColor: "#f2f6fc" }}
          >
            <h3 className="email_composer_heading_text">New Message</h3>
            <IoClose onClick={onClose} />
          </div>

          <Stack spacing={1}>
            <Flex align="center" className="email_composer_text_to">
              <Text w="30px">To:</Text>
              <Input
                variant="flushed"
                value={recipientEmail}
                onDoubleClick={handleDoubleClick}
                onBlur={handleBlur}
                onChange={(e) => setRecipientEmail(e.target.value)}
                border="2px solid #4A90E2"
                _focus={{ borderColor: "#FF6347" }}
              />
              {!showCC && type!=="prospect" && (
                <button onClick={handleCCClick} className="cc_button">
                  CC
                </button>
              )}
              {!showBCC && type!=="prospect" && (
                <button onClick={handleBCCClick} className="bcc_button">
                  BCC
                </button>
              )}
            </Flex>

            {showCC && type!=="prospect" && (
              <Flex className="email_composer_text_cc">
                <Text w="30px">cc:</Text>
                <Input
                  variant="flushed"
                  value={ccMail}
                  onChange={(e) => setccMail(e.target.value)}
                  border="2px solid #4A90E2"
                  _focus={{ borderColor: "#FF6347" }}
                />
              </Flex>
            )}
            {showBCC && type!=="prospect" && (
              <Flex className="email_composer_text_bcc">
                <Text w="30px">bcc:</Text>
                <Input
                  variant="flushed"
                  value={bccMail}
                  onChange={(e) => setbccMail(e.target.value)}
                  border="2px solid #4A90E2"
                  _focus={{ borderColor: "#FF6347" }}
                />
              </Flex>
            )}
            <Input
              variant="flushed"
              value={subject}
              placeholder="subject"
              onChange={(e) => setSubject(e.target.value)}
              border="2px solid #4A90E2"
              _focus={{ borderColor: "#FF6347" }}
            />
            <Box as="div" className="email_composer_text_editor">
              <div className="email_composer_custom_quill">
                <ReactQuill
                  ref={quillRef}
                  value={emailContent}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  className="quill-editor"
                />
              </div>
              {isEmailEmpty && (
                <span
                  className="email_composer_error"
                  style={{
                    color: "red",
                    position: "absolute",
                    bottom: "128px",
                  }}
                >
                  {isEmailEmpty || "Email message is required!"}
                </span>
              )}
            </Box>
          </Stack>
          <Box as="div">
            <Flex className="new_email_composer_footer_area" mt={isClassActive ? "100px" : isMobile ? "30px" : "60px"}>
              <Box display="flex" alignItems="center">
                <Button
                  colorScheme="blue"
                  size="lg"
                  borderRadius="8"
                  onClick={handleSendEmail}
                  height="40px"
                  width="100px"
                  fontSize="17px"
                  loadingText=""
                  isLoading={loading}
                >
                  Send
                </Button>
              </Box>

              <Tooltip label="Formatting Text" aria-label="Formatting Text">
                <IconButton
                  ml={2}
                  icon={<MdFormatColorText fontSize="24px" />}
                  aria-label="Formatting Text"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={ShowFormattingText}
                />
              </Tooltip>

              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <Tooltip label="Attach File" aria-label="Attach File">
                <label htmlFor="file-upload">
                  <IconButton
                    as="span"
                    ml={2}
                    icon={<MdOutlineAttachFile fontSize="24px" />}
                    aria-label="Attach File"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                  />
                </label>
              </Tooltip>

              {fileName && (
                <Text ml={3} fontSize="sm" color="gray.500">
                  {fileName}
                </Text>
              )}

              <Popover
                isOpen={showEmojiPicker}
                onClose={() => {
                  setShowEmojiPicker(false)
                }}
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <IconButton
                    ml={2}
                    icon={<CgSmileMouthOpen fontSize="24px" />}
                    aria-label="Insert Emoji"
                    bg="transparent"
                    className="emoji-icon-container"
                    _hover={{ bg: "transparent" }}
                    onClick={() => setShowEmojiPicker((prev) => !prev)}
                  />
                </PopoverTrigger>
                <PopoverContent ref={popoverRef}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <Box p={2} className="emoji-icon-container">
                    <EmojiPicker 
                      width={302} 
                      height={350} 
                      onEmojiClick={(emojiData, event) => handleAddEmoji(emojiData, event)}
                    />
                  </Box>
                </PopoverContent>
                <Box>
                  <Tooltip label="Email-Template" aria-label="Email-Template">
                    <label htmlFor="Email-Template">
                      <IconButton
                        as="span"
                        ml={2}
                        icon={<HiTemplate fontSize="24px" />}
                        aria-label="Attach File"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        onClick={handleShowEmailsTemplates}
                      />
                    </label>
                  </Tooltip>

                </Box>
              </Popover>
              <div className="email_composer_del_icon"> 
                <IconButton
                  as="span"
                  ml={2}
                  icon={<RiDeleteBin6Line fontSize="24px" />}
                  aria-label="Attach File"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={onClose}
                />
              </div>
              {/* <RiDeleteBin6Line
                className="email_composer_del_icon"
                style={{
                  fontSize: "24px",
                  right: "25px",
                  position: "absolute",
                }}
                onClick={onClose}
              /> */}
            </Flex>
          </Box>
        </Box>
        {openPopup && (
          <Modal isOpen={isEmailComposer} onClose={() => setOpenPopup(false)}>
            <ModalOverlay />
            <ModalContent style={{ height: "500px", overflow: "auto" }}>
              <ModalHeader>Email Templates</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="email-templates-list">
                  {emailTemplateData.map((template) => (
                    <div
                      key={template.id}
                      className="template-item"
                      onClick={() =>
                        handleTemplateSelect(template.name, template.body)
                      }
                      style={{
                        cursor: "pointer",
                        margin: "8px 0",
                        padding: "10px",
                        border: "1px solid transparent",
                        borderRadius: "4px",
                        transition: "background-color 0.2s, border 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#f0f0f0";
                        e.currentTarget.style.border = "1px solid #ccc";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.border = "1px solid transparent";
                      }}
                    >
                      <span>{template.name}</span>
                      <span style={{ marginLeft: "8px", color: "#888" }}>
                        →
                      </span>
                    </div>
                  ))}
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div >
    </>
  );
}
